define("ember-cli-materialize/components/md-default-column-header", ["exports", "ember-cli-materialize/templates/components/md-default-column-header"], function (_exports, _mdDefaultColumnHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'th',
    layout: _mdDefaultColumnHeader.default,
    attributeBindings: ['data-field'],
    'data-field': Ember.computed.alias('column.valueBindingPath')
  });
});