define("ember-google-maps/templates/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aoIXktJw",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[9,\"div\",false],[14,\"id\",[27,[26,0,\"AppendSingleId\"],[]],null],[14,\"class\",[27,[26,1,\"AppendSingleId\"],[]],null],[14,\"style\",[27,[26,2,\"AppendSingleId\"],[]],null],[15,1],[10],[16,2,null],[11]],\"hasEval\":false,\"upvars\":[\"id\",\"computedClasses\",\"style\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/canvas.hbs"
    }
  });
});