define("ember-keyboard/mixins/ember-keyboard", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "ember-keyboard/utils/handle-key-event"], function (_exports, _createForOfIteratorHelper2, _handleKeyEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(Ember.Evented, {
    keyboardPriority: 0,
    keyboard: Ember.inject.service(),
    init: function init() {
      (true && !(false) && Ember.deprecate('`EKMixin` of ember-keyboard is deprecated. Please use the @keyResponder decorator instead.', false, {
        id: 'ember-keyboard.ember-keyboard-mixin',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#ember-keyboard-mixin'
      }));
      this.keyboard.register(this);
      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.keyboard.unregister(this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.keyboard.unregister(this);
    },
    // These next two methods adapt this mixin to conform to the new responder API.
    // In the future, once we have good alternatives, we expect all of this addon's
    // mixins to be deprecated and removed, but for now this will let it execute
    // without triggering deprecation warnings.
    canHandleKeyboardEvent: function canHandleKeyboardEvent(event) {
      var _iterator = (0, _createForOfIteratorHelper2.default)((0, _handleKeyEvent.getListenerNames)(event)),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var listenerName = _step.value;
          if (this.has(listenerName)) {
            return true;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return false;
    },
    handleKeyboardEvent: function handleKeyboardEvent(event, ekEvent) {
      (0, _handleKeyEvent.triggerViaLegacyResponderApi)(this, event, ekEvent);
    }
  });
});