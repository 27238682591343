define("ember-math-helpers/helpers/gcd", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.gcd = gcd;
  /**
   * Returns the greatest positive integer that divides each of two integers
   *
   * ```hbs
   * {{gcd a b}}
   * ```
   *
   * @param {number} number1 The first number
   * @param {number} number2 The second number
   * @return {number} The GCD of the two numbers passed
   */
  function gcd(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      _ref2$ = _ref2[0],
      number1 = _ref2$ === void 0 ? 0 : _ref2$,
      _ref2$2 = _ref2[1],
      number2 = _ref2$2 === void 0 ? 0 : _ref2$2;
    var a = Math.abs(number1);
    var b = Math.abs(number2);
    if (a === 0) {
      return b;
    }
    if (b === 0) {
      return a;
    }
    return gcd([b, a % b]);
  }
  var _default = _exports.default = Ember.Helper.helper(gcd);
});