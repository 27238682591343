define("ember-cli-materialize/components/md-input", ["exports", "ember-cli-materialize/components/md-input-field", "ember-cli-materialize/templates/components/md-input"], function (_exports, _mdInputField, _mdInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = _exports.default = _mdInputField.default.extend({
    layout: _mdInput.default,
    type: 'text',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // make sure the label moves when a value is bound.
      this._setupLabel();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this._setupLabel();
    },
    actions: {
      enter: function enter() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        this._sendAction.apply(this, ['onEnter'].concat(args));
      },
      keyPress: function keyPress() {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }
        this._sendAction.apply(this, ['onKeyPress'].concat(args));
      },
      keyUp: function keyUp() {
        for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }
        this._sendAction.apply(this, ['onKeyUp'].concat(args));
      }
    },
    _sendAction: function _sendAction(action) {
      for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        args[_key4 - 1] = arguments[_key4];
      }
      if (typeof get(this, action) === 'function') {
        get(this, action).apply(void 0, args);
      } else {
        this.sendAction.apply(this, [action].concat(args));
      }
    }
  });
});