define("ember-ajax/-private/utils/parse-response-headers", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CRLF = void 0;
  _exports.default = parseResponseHeaders;
  var CRLF = _exports.CRLF = "\r\n";
  function parseResponseHeaders(headersString) {
    var headers = {};
    if (!headersString) {
      return headers;
    }
    return headersString.split(CRLF).reduce(function (hash, header) {
      var _header$split = header.split(':'),
        _header$split2 = (0, _toArray2.default)(_header$split),
        field = _header$split2[0],
        value = _header$split2.slice(1);
      field = field.trim();
      var valueString = value.join(':').trim();
      if (valueString) {
        hash[field] = valueString;
      }
      return hash;
    }, headers);
  }
});