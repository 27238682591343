define("ember-cli-materialize/components/md-modal", ["exports", "ember-cli-materialize/mixins/uses-settings", "ember-cli-materialize/templates/components/md-modal", "ember-keyboard"], function (_exports, _usesSettings, _mdModal, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, _usesSettings.default, {
    layout: _mdModal.default,
    attributeBindings: ['style:inlineStyle'],
    concatenatedProperties: ['modalClassNames'],
    inlineStyle: Ember.computed(function () {
      return Ember.String.htmlSafe('z-index: 1000;');
    }),
    isFooterFixed: Ember.computed.oneWay('_mdSettings.modalIsFooterFixed'),
    modalClassNames: ['modal', 'show'],
    _modalClassString: Ember.computed('modalClassNames.[]', 'isFooterFixed', function () {
      var names = this.get('modalClassNames').slice(0); // copies property
      if (this.get('isFooterFixed')) {
        names.push('modal-fixed-footer');
      }
      return names.join(' ');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('keyboardActivated', true);
    },
    _onEsc: Ember.on((0, _emberKeyboard.keyUp)('Escape'), function () {
      this.cancel();
    }),
    cancel: function cancel() {
      this.sendAction('close');
    },
    actions: {
      closeModal: function closeModal() {
        this.sendAction('close');
      }
    }
  });
});