define("ember-cli-materialize/templates/components/md-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HS++H5bx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],null,[[\"alignment\",\"alignmentTarget\",\"translucentOverlay\",\"overlayClassNames\",\"clickOutsideToClose\",\"onClose\"],[[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],true,\"lean-modal\",true,\"closeModal\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]]]],null],[12,\"style\",\"display: block; opacity: 1; top: 10%;\",null],[10],[1,1,0,0,\"\\n    \"],[16,1,null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_modalClassString\",\"alignmentTarget\",\"alignment\",\"modal-dialog\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-modal.hbs"
    }
  });
});