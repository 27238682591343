define("ember-composable-helpers/helpers/filter-by", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/is-equal", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _isEqual, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filterBy = filterBy;
  function filterBy(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
      byPath = _ref2[0],
      value = _ref2[1],
      array = _ref2[2];
    if (!Ember.isArray(array) && Ember.isArray(value)) {
      array = value;
      value = undefined;
    }
    array = (0, _asArray.default)(array);
    if (Ember.isEmpty(byPath) || Ember.isEmpty(array)) {
      return [];
    }
    var filterFn;
    if (Ember.isPresent(value)) {
      if (typeof value === 'function') {
        filterFn = function filterFn(item) {
          return value(Ember.get(item, byPath));
        };
      } else {
        filterFn = function filterFn(item) {
          return (0, _isEqual.default)(Ember.get(item, byPath), value);
        };
      }
    } else {
      filterFn = function filterFn(item) {
        return !!Ember.get(item, byPath);
      };
    }
    return array.filter(filterFn);
  }
  var _default = _exports.default = Ember.Helper.helper(filterBy);
});