define("ember-composable-helpers/helpers/intersect", ["exports", "@babel/runtime/helpers/esm/toArray", "ember-composable-helpers/utils/as-array"], function (_exports, _toArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.intersect = intersect;
  function intersect(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
      arrays = _ref2.slice(0);
    var confirmedArrays = (0, _asArray.default)(arrays).map(function (array) {
      return Ember.isArray(array) ? array : [];
    });
    // copied from https://github.com/emberjs/ember.js/blob/315ec6472ff542ac714432036cc96fe4bd62bd1f/packages/%40ember/object/lib/computed/reduce_computed_macros.js#L1063-L1100
    var results = confirmedArrays.pop().filter(function (candidate) {
      for (var i = 0; i < confirmedArrays.length; i++) {
        var found = false;
        var array = confirmedArrays[i];
        for (var j = 0; j < array.length; j++) {
          if (array[j] === candidate) {
            found = true;
            break;
          }
        }
        if (found === false) {
          return false;
        }
      }
      return true;
    });
    return results;
  }
  var _default = _exports.default = Ember.Helper.helper(intersect);
});