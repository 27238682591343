define("ember-cli-materialize/templates/components/md-fixed-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "a0E/hbAU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"icon\",\"class\",\"action\"],[[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]],\"fireButtonAction\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_btnClassString\",\"btnIcon\",\"md-btn\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-fixed-btn.hbs"
    }
  });
});