define("ember-data-model-fragments/transforms/fragment", ["exports", "@ember-data/serializer/transform", "@ember-data/serializer/json-api"], function (_exports, _transform, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.fragment` fragment attribute which delegates work to
    the fragment type's serializer
  
    @class FragmentTransform
    @namespace MF
    @extends DS.Transform
  */
  var FragmentTransform = _transform.default.extend({
    store: Ember.inject.service(),
    type: null,
    polymorphicTypeProp: null,
    deserialize: function deserializeFragment(data, options, parentData) {
      if (data == null) {
        return null;
      }
      return this.deserializeSingle(data, options, parentData);
    },
    serialize: function serializeFragment(snapshot) {
      if (!snapshot) {
        return null;
      }
      var store = this.store;
      var realSnapshot = snapshot._createSnapshot ? snapshot._createSnapshot() : snapshot;
      var serializer = store.serializerFor(realSnapshot.modelName || realSnapshot.constructor.modelName);
      return serializer.serialize(realSnapshot);
    },
    modelNameFor: function modelNameFor(data, options, parentData) {
      var modelName = this.type;
      var polymorphicTypeProp = this.polymorphicTypeProp;
      if (data && polymorphicTypeProp && data[polymorphicTypeProp]) {
        modelName = data[polymorphicTypeProp];
      } else if (options && typeof options.typeKey === 'function') {
        modelName = options.typeKey(data, parentData);
      }
      return modelName;
    },
    deserializeSingle: function deserializeSingle(data, options, parentData) {
      var store = this.store;
      var modelName = this.modelNameFor(data, options, parentData);
      var serializer = store.serializerFor(modelName);
      (true && !(!(serializer instanceof _jsonApi.default)) && Ember.assert('The `JSONAPISerializer` is not suitable for model fragments, please use `JSONSerializer`', !(serializer instanceof _jsonApi.default)));
      var typeClass = store.modelFor(modelName);
      var serialized = serializer.normalize(typeClass, data);

      // `JSONSerializer#normalize` returns a full JSON API document, but we only
      // need the attributes hash
      return Ember.get(serialized, 'data.attributes');
    }
  });
  var _default = _exports.default = FragmentTransform;
});