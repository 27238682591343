define("ember-feature-flags/helpers/feature-flag", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    features: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        flag = _ref2[0];
      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }
      this.set('_observedFlag', Ember.String.camelize(flag));
      this.get('features').addObserver(this._observedFlag, this, 'recompute');
      return this.get('features').isEnabled(flag);
    },
    _observedFlag: null,
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }
    }
  });
});