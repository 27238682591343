define("ember-modal-dialog/templates/components/in-place-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SJj+2vEi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[13,\"class\",[31,15,6,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]],\" \",[27,[26,1,\"Expression\"],[]],\" \",[27,[26,0,\"Expression\"],[]]],null],null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"containerClass\",\"attachmentClass\",\"containerClassNamesString\",\"concat\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs"
    }
  });
});