define("ember-concurrency/helpers/task", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function taskHelper(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
      task = _ref2[0],
      args = _ref2.slice(1);
    return task._curry.apply(task, (0, _toConsumableArray2.default)(args));
  }
  var _default = _exports.default = Ember.Helper.helper(taskHelper);
});