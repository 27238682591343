define("ember-cli-materialize/components/md-modal-container", ["exports", "ember-cli-materialize/mixins/uses-settings", "ember-cli-materialize/templates/components/md-modal-container"], function (_exports, _usesSettings, _mdModalContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_usesSettings.default, {
    layout: _mdModalContainer.default,
    modalContainerId: null,
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('modalContainerId')) {
        this.set('modalContainerId', this.get('_mdSettings.modalContainerId'));
      }
    }
  });
});