define("ember-cli-materialize/templates/components/md-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZpBhihzL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,0,0,0,[31,2,12,[27,[26,3,\"CallHead\"],[]],null,[[\"disabled\",\"value\",\"groupValue\",\"radioClass\"],[[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]],\"materialize-selectable-item-input\"]]]],[1,1,0,0,\"\\n\"],[9,\"label\",true],[12,\"class\",\"materialize-selectable-item-label materialize-selectable-item-label\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,4,\"AppendSingleId\"],[]]],[16,1,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"groupValue\",\"value\",\"disabled\",\"radio-button\",\"name\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-radio.hbs"
    }
  });
});