define("liquid-fire/templates/components/liquid-spacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6xOZEZhm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],null,[[\"didMeasure\"],[[31,31,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,0],[\"sizeChanged\"]]],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"liquid-measured\"]}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-spacer.hbs"
    }
  });
});