define("ember-data-copyable/-private/symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IS_COPYABLE = _exports.COPY_TASK_RUNNER = _exports.COPY_TASK = void 0;
  var COPY_TASK = _exports.COPY_TASK = '__DS_COPY_TASK__';
  var COPY_TASK_RUNNER = _exports.COPY_TASK_RUNNER = '__DS_COPY_TASK_RUNNER__';
  var IS_COPYABLE = _exports.IS_COPYABLE = '__DS_IS_COPYABLE__';
});