define("ember-google-maps/mixins/process-options", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-google-maps/utils/options-and-events"], function (_exports, _toConsumableArray2, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class ProcessOptions
   * @module ember-google-maps/mixins/process-options
   * @extends Ember.Mixin
   */
  var _default = _exports.default = Ember.Mixin.create({
    concatenatedProperties: ['_requiredOptions', '_watchedOptions', '_ignoredAttrs'],
    /**
     * Specify which attributes on the component should be ignored and never
     * considered as a Google Maps option or event.
     *
     * @property _ignoredAttrs
     * @private
     * @type {String[]}
     */
    _ignoredAttrs: ['map', '_internalAPI', 'gMap', 'lat', 'lng', 'events', '_name'],
    /**
     * Required options that are always included in the options object passed to
     * the map component.
     *
     * @property _requiredOptions
     * @private
     * @type {String[]}
     */
    _requiredOptions: [],
    /**
     * Paths to watch for changes. The paths follow the same syntax as the keys
     * for Ember observers and computed properties.
     *
     * @property _watchedOptions
     * @private
     * @type {String[]}
     */
    _watchedOptions: [],
    /**
     * Combined object of options and events used to set and update the options
     * on the map component.
     *
     * @property options
     * @public
     * @return {Object}
     */
    options: Ember.computed('attrs', 'events', '_ignoredAttrs', '_eventAttrs', function () {
      var _Ember$getProperties = Ember.getProperties(this, '_ignoredAttrs', '_eventAttrs'),
        _ignoredAttrs = _Ember$getProperties._ignoredAttrs,
        _eventAttrs = _Ember$getProperties._eventAttrs;
      var ignoredAttrs = [].concat((0, _toConsumableArray2.default)(_ignoredAttrs), (0, _toConsumableArray2.default)(_eventAttrs));
      var attrs = Object.keys(this.attrs).filter(function (attr) {
        return ignoredAttrs.indexOf(attr) === -1;
      });
      return Ember.getProperties(this, attrs);
    }),
    _options: Ember.computed('map', 'options', '_requiredOptions', function () {
      var options = Ember.get(this, 'options');
      var _requiredOptions = Ember.get(this, '_requiredOptions');
      var required = Ember.getProperties(this, _requiredOptions);
      return Object.assign({}, required, options);
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      (true && !(false) && Ember.deprecate("\nThe `ProcessOptions` mixin will be removed in the next major version of ember-google-maps. If you need to manually parse component attributes, use the functions provided in `ember-google-maps/utils/options-and-events`.", false, {
        id: 'process-options-mixin-removed',
        until: '4.0'
      }));
      this._watchedListeners = new Map();
      if (!this._eventAttrs) {
        Ember.set(this, '_eventAttrs', []);
      }
      this._isInitialized = false;
      this.isInitialized = Ember.RSVP.defer();
      this.isInitialized.promise.then(function () {
        return Ember.set(_this, '_isInitialized', true);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._watchedListeners.forEach(function (remove) {
        return remove();
      });
      this._super.apply(this, arguments);
    },
    _registerOptionObservers: function _registerOptionObservers() {
      var _this2 = this;
      var _watchedOptions = Ember.get(this, '_watchedOptions');
      if (_watchedOptions.length === 0) {
        return;
      }
      function update() {
        if (this._isInitialized) {
          this._updateComponent();
        }
      }
      var watched = {};
      _watchedOptions.forEach(function (path) {
        return watched[path] = update.bind(_this2);
      });
      (0, _optionsAndEvents.watch)(this, watched).forEach(function (_ref) {
        var name = _ref.name,
          remove = _ref.remove;
        return _this2._watchedListeners.set(name, remove);
      });
    }
  });
});