define("ember-data-change-tracker/utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relationshipKnownState = _exports.relationShipTransform = _exports.modelTransform = _exports.isEmpty = _exports.didSerializedModelChange = _exports.didModelsChange = _exports.didModelChange = void 0;
  var modelTransform = _exports.modelTransform = function modelTransform(model, polymorphic) {
    if (polymorphic) {
      return {
        id: model.id,
        type: model.modelName || model.constructor.modelName
      };
    }
    return model.id;
  };
  var relationShipTransform = _exports.relationShipTransform = {
    belongsTo: {
      serialize: function serialize(model, key, options) {
        var relationship = model.belongsTo(key).belongsToRelationship;
        var value = relationship.hasOwnProperty('inverseRecordData') ? relationship.inverseRecordData : relationship.canonicalState;
        return value && modelTransform(value, options.polymorphic);
      },
      deserialize: function deserialize() {}
    },
    hasMany: {
      serialize: function serialize(model, key, options) {
        var relationship = model.hasMany(key).hasManyRelationship;
        var value = relationship.currentState;
        return value && value.map(function (item) {
          return modelTransform(item, options.polymorphic);
        });
      },
      deserialize: function deserialize() {}
    }
  };
  var relationshipKnownState = _exports.relationshipKnownState = {
    belongsTo: {
      isKnown: function isKnown(model, key) {
        var belongsTo = model.belongsTo(key);
        var relationship = belongsTo.belongsToRelationship;
        return !relationship.relationshipIsStale;
      }
    },
    hasMany: {
      isKnown: function isKnown(model, key) {
        var hasMany = model.hasMany(key);
        var relationship = hasMany.hasManyRelationship;
        return !relationship.relationshipIsStale;
      }
    }
  };
  var isEmpty = _exports.isEmpty = function isEmpty(value) {
    if (Ember.typeOf(value) === 'object') {
      return Object.keys(value).length === 0;
    }
    return Ember.isEmpty(value);
  };
  var didSerializedModelChange = _exports.didSerializedModelChange = function didSerializedModelChange(one, other, polymorphic) {
    if (polymorphic) {
      return one.id !== other.id || one.type !== other.type;
    }
    return one !== other;
  };
  var didModelsChange = _exports.didModelsChange = function didModelsChange(one, other, polymorphic) {
    if (isEmpty(one) && isEmpty(other)) {
      return false;
    }
    if ((one && one.length) !== (other && other.length)) {
      return true;
    }
    for (var i = 0, len = one.length; i < len; i++) {
      if (didSerializedModelChange(one[i], other[i], polymorphic)) {
        return true;
      }
    }
    return false;
  };
  var didModelChange = _exports.didModelChange = function didModelChange(one, other, polymorphic) {
    if (isEmpty(one) && isEmpty(other)) {
      return false;
    }
    if (!one && other || one && !other) {
      return true;
    }
    return didSerializedModelChange(one, other, polymorphic);
  };
});