define("ember-cli-materialize/components/md-btn-submit", ["exports", "ember-cli-materialize/components/md-btn"], function (_exports, _mdBtn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mdBtn.default.extend({
    layoutName: 'components/materialize-button',
    tagName: 'button',
    attributeBindings: ['type'],
    type: 'submit'
  });
});