define("ember-cli-materialize/templates/components/md-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FSkFkwTu",
    "block": "{\"symbols\":[\"item\",\"idx\",\"&default\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,23,9,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],[[\"header\"],[[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[5,[27,[26,7,\"BlockHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,3,[[27,[24,1],[]],[27,[24,2],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"header\",\"headerComponentName\",\"component\",\"_hasHeader\",\"if\",\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-collection.hbs"
    }
  });
});