define("ember-composability/mixins/parent-component-support", ["exports", "@ember/ordered-set"], function (_exports, _orderedSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    _childComponents: null,
    composableChildrenDebounceTime: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_childComponents', new _orderedSet.default());
    },
    composableChildren: Ember.computed(function () {
      return this.getComposableChildren();
    }).readOnly(),
    getComposableChildren: function getComposableChildren() {
      var comps = this.get('_childComponents');
      return Ember.A(comps && comps.size ? this.get('_childComponents').list : []);
    },
    _fireComposableChildrenChanged: function _fireComposableChildrenChanged() {
      if (typeof this.notifyPropertyChange === 'function') {
        this.notifyPropertyChange('composableChildren');
      } else if (typeof this.propertyDidChange === 'function') {
        // Deprecated in ember 3.1
        this.propertyDidChange('composableChildren');
      } else {
        throw new Error('Unable to call notifyPropertyChange');
      }
    },
    _notifyComposableChildrenChanged: function _notifyComposableChildrenChanged() {
      if (this.get('composableChildrenDebounceTime')) {
        Ember.run.debounce(this, this._fireComposableChildrenChanged, this.get('composableChildrenDebounceTime'));
      } else {
        this._fireComposableChildrenChanged();
      }
    },
    registerChildComponent: function registerChildComponent(childComponent) {
      this.get('_childComponents').add(childComponent);
      this._notifyComposableChildrenChanged();
    },
    unregisterChildComponent: function unregisterChildComponent(childComponent) {
      this.get('_childComponents').delete(childComponent);
      this._notifyComposableChildrenChanged();
    }
  });
});