define("ember-keyboard/helpers/if-key", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-keyboard/utils/is-key", "ember-keyboard/utils/listener-name"], function (_exports, _slicedToArray2, _isKey, _listenerName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function ifKey(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      keyCombo = _ref2[0],
      callback = _ref2[1];
    return function (event) {
      (true && !(typeof callback === 'function') && Ember.assert('ember-keyboard: You must pass a function as the second argument to the `if-key` helper', typeof callback === 'function'));
      (true && !(event instanceof KeyboardEvent) && Ember.assert('ember-keyboard: The `if-key` helper expects to be invoked with a KeyboardEvent', event instanceof KeyboardEvent));
      if ((0, _isKey.default)((0, _listenerName.default)(event.type, keyCombo), event)) {
        callback(event);
      }
    };
  });
});