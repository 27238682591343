define("ember-cli-materialize/components/md-select", ["exports", "ember-cli-materialize/components/md-input-field", "ember-cli-materialize/templates/components/md-select"], function (_exports, _mdInputField, _mdSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mdInputField.default.extend({
    layout: _mdSelect.default,
    classNames: ['md-select'],
    optionLabelPath: 'content',
    optionValuePath: 'content',
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._setupSelect();
    },
    willUpdate: function willUpdate() {
      this._teardownSelect();
    },
    willDestroyElement: function willDestroyElement() {
      this._teardownSelect();
    },
    _setupSelect: function _setupSelect() {
      // jscs: disable
      this.$('select').material_select('destroy');
      this.$('select').material_select();
      // jscs: enable
    },
    _parsedContent: Ember.computed('optionValuePath', 'optionLabelPath', 'content.[]', function () {
      var contentRegex = /(content\.|^content$)/;
      // keep backwards compatability for defining optionValuePath & as optionContentPath `content.{{attName}}`
      var optionValuePath = (this.get('optionValuePath') || '').replace(contentRegex, '');
      var optionLabelPath = (this.get('optionLabelPath') || '').replace(contentRegex, '');
      return Ember.A((this.get('content') || []).map(function (option) {
        return {
          value: optionValuePath ? Ember.get(option, optionValuePath) : option,
          label: optionLabelPath ? Ember.get(option, optionLabelPath) : option
        };
      }));
    }),
    _teardownSelect: function _teardownSelect() {
      // jscs: disable
      this.$('select').material_select('destroy');
      // jscs: enable
    },
    actions: {
      optionSelected: function optionSelected(e) {
        var target = e.target;
        if (!this.get('isDisabled')) {
          if (this.get('action')) {
            this.sendAction('action', target.value);
          } else {
            this.set('value', target.value);
          }
        }
      }
    },
    // TODO: this could be converted to a computed property, returning a string
    //  that is bound to the class attribute of the inputSelector
    errorsDidChange: Ember.observer('errors', function () {
      var inputSelector = this.$('input');
      // monitor the select's validity and copy the appropriate validation class to the materialize input element.
      if (!Ember.isNone(inputSelector)) {
        Ember.run.later(this, function () {
          var isValid = this.$('select').hasClass('valid');
          if (isValid) {
            inputSelector.removeClass('invalid');
            inputSelector.addClass('valid');
          } else {
            inputSelector.removeClass('valid');
            inputSelector.addClass('invalid');
          }
        }, 150);
      }
    })
  });
});