define("ember-cli-materialize/templates/components/md-default-column-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6QwGm7Y/",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"Expression\"],[\"header\"]]]],\"hasEval\":false,\"upvars\":[\"column\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-default-column-header.hbs"
    }
  });
});