define("ember-cli-materialize/templates/components/md-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4vjNq1Zn",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"col s12\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"ul\",true],[12,\"class\",\"tabs\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[31,82,6,[27,[26,0,\"CallHead\"],[]],null,[[\"title\",\"value\"],[[27,[24,1],[\"title\"]],[27,[24,1],[\"id\"]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[16,2,null],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"indicator indicator1\",null],[10],[11],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"indicator indicator2\",null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"md-tab\",\"_content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-tabs.hbs"
    }
  });
});