define("ember-composable-helpers/helpers/reduce", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reduce = reduce;
  function reduce(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
      callback = _ref2[0],
      initialValue = _ref2[1],
      array = _ref2[2];
    if (Ember.isEmpty(callback)) {
      return [];
    }
    return (0, _asArray.default)(array).reduce(callback, initialValue);
  }
  var _default = _exports.default = Ember.Helper.helper(reduce);
});