define("ember-cli-materialize/components/md-tab", ["exports", "ember-composability/mixins/child-component-support", "ember-cli-materialize/components/md-tabs", "ember-cli-materialize/templates/components/md-tab"], function (_exports, _childComponentSupport, _mdTabs, _mdTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_childComponentSupport.default, {
    _parentComponentTypes: [_mdTabs.default],
    tagName: 'li',
    layout: _mdTab.default,
    classNames: ['materialize-tabs-tab', 'tab', 'col'],
    classNameBindings: ['_colClass'],
    colWidth: Ember.computed.oneWay('composableParent.colWidth'),
    _colClass: Ember.computed('colWidth', function () {
      return "s".concat(this.get('colWidth'));
    }),
    active: Ember.computed('composableParent.composableChildren.[]', 'composableParent.selected', 'value', function () {
      var selected = this.get('composableParent.selected');
      if (selected) {
        return selected === this.get('value');
      } else {
        var values = this.get('composableParent').tabComponents().map(function (t) {
          return t.get('value');
        });
        return values.indexOf(this.get('value')) === 0;
      }
    }).readOnly(),
    click: function click() {
      this.get('composableParent').set('selected', this.get('value'));
    }
  });
});