define("ember-light-table/templates/components/lt-spanned-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QkvfWhiF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"tr\",true],[13,\"class\",[32,[\"lt-row \",[31,38,9,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"td\",true],[13,\"colspan\",[27,[26,2,\"AppendSingleId\"],[]],null],[10],[1,1,0,0,\"\\n      \"],[16,1,[[27,[26,3,\"Expression\"],[]]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"classes\",\"html-safe\",\"colspan\",\"yield\",\"visible\",\"if\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-spanned-row.hbs"
    }
  });
});