define("ember-math-helpers/helpers/log-e", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.logE = logE;
  /**
   * Executes `Math.log` on the number passed to the helper.
   *
   * ```hbs
   * {{log-e a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.log`
   * @return {number} The log of the passed number
   */
  function logE(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      number = _ref2[0];
    return Math.log(number);
  }
  var _default = _exports.default = Ember.Helper.helper(logE);
});