define("ember-cli-materialize/mixins/group-selectable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    name: Ember.computed.alias('content.label'),
    value: Ember.computed.alias('content.value'),
    disabled: false
  });
});