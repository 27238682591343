define("ember-keyboard/utils/platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPlatform;
  var platform;
  function getPlatform() {
    var userAgent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : navigator.userAgent;
    // allow mocking of userAgent in tests, memoize for speed in production
    Ember.runInDebug(function () {
      platform = null;
    });
    if (!platform) {
      var osName = "Unknown OS";
      if (userAgent.indexOf("Win") != -1) osName = "Windows";
      if (userAgent.indexOf("Mac") != -1) osName = "Macintosh";
      if (userAgent.indexOf("Linux") != -1) osName = "Linux";
      if (userAgent.indexOf("Android") != -1) osName = "Android";
      if (userAgent.indexOf("like Mac") != -1) osName = "iOS";
      platform = osName;
    }
    return platform;
  }
});