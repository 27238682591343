define("ember-composability/mixins/child-component-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      (true && !(this.get('_parentComponentTypes')) && Ember.assert('Must define _parentComponentTypes', this.get('_parentComponentTypes')));
      this._registerWithParent();
    },
    willDestroyElement: function willDestroyElement() {
      this._unregisterWithParent();
      this._super.apply(this, arguments);
    },
    composableParent: Ember.computed(function () {
      return this._componentToRegisterTo();
    }),
    _componentToRegisterTo: function _componentToRegisterTo() {
      var c = null;
      var parentTypes = this.get('_parentComponentTypes');
      for (var i = 0; i < parentTypes.length && !c; i++) {
        c = this.nearestOfType(parentTypes[i]);
      }
      return c;
    },
    shouldRegisterToParent: function shouldRegisterToParent() {
      return true;
    } /* parentComponent*/,
    _registerWithParent: function _registerWithParent() {
      var parentComponent = this._componentToRegisterTo();
      if (parentComponent) {
        if (this.shouldRegisterToParent(parentComponent)) {
          parentComponent.registerChildComponent(this);
        }
        this.set('composableParent', parentComponent);
      }
    },
    _unregisterWithParent: function _unregisterWithParent() {
      var parentComponent = this._componentToRegisterTo();
      if (parentComponent) {
        parentComponent.unregisterChildComponent(this);
      }
    }
  });
});