define("ember-cli-materialize/templates/components/md-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/dL6dIy5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-badge.hbs"
    }
  });
});