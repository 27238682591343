define("ember-cli-materialize/components/md-check", ["exports", "ember-cli-materialize/components/selectable-item", "ember-cli-materialize/templates/components/md-checkbox"], function (_exports, _selectableItem, _mdCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _selectableItem.default.extend({
    layout: _mdCheckbox.default,
    text: Ember.computed.alias('name'),
    classNames: ['materialize-checkbox']
  });
});