define("liquid-fire/ember-internals/get-outlet-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h6RrbQGu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,16,[27,[26,0,\"CallHead\"],[]],[\"outletState\"],null]]]],\"hasEval\":false,\"upvars\":[\"-get-dynamic-var\"]}",
    "meta": {
      "moduleName": "liquid-fire/ember-internals/get-outlet-state.hbs"
    }
  });
});