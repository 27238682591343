define("ember-tooltips/templates/components/ember-tooltip-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r6DqnFga",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"_awaitingTooltipElementRendered\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,0],[\"_shouldRenderContent\"]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"        \"],[16,1,[[27,[24,0],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[27,[24,0],[\"text\"]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"_renderElement\"]]],null]],[[\"guid\",\"guid\",\"insertBefore\"],[\"%cursor:0%\",\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"        \"],[16,1,[[27,[24,0],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"        \"],[1,0,0,0,[27,[24,0],[\"text\"]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-clear-element\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-tooltips/templates/components/ember-tooltip-base.hbs"
    }
  });
});