define("ember-cli-mirage/serializers/ember-data-serializer", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "miragejs", "lodash-es"], function (_exports, _slicedToArray2, _miragejs, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This serializer does not use following mirage properties to control how things are serialized
   *
   *     attrs - see `serialize` on the transform property
   *     embed - see `serialize` on the transform property
   *     serializeIds - see serialize on the transform property
   *
   * The above configuration was applied to every property on the serializer, whereas the transforms allows you
   * to specify a value for each property or relation.
   *
   * This serializer uses a property `transforms` that follows the Ember Data serializer format of `attrs` to specify the
   * serialization (`attrs` is already in use by mirageJs).
   *
   * The startMirage has been modified to also generate serializers from the Ember Data serializers supporting
   * the key and the serialize/deserialize properties with the value of (true/ids/records). If a serializer is
   * already present in the mirage directory, the transforms will be added to it. If that serializer is not
   * a serializer of this type, there will be no effect.  Ensure that your serializers and/or the application
   * serializer in the mirage directory is a type of this serializer
   *
   * @class EmberDataSerializer
   * @constructor
   * @public
   *
   */
  var EmberDataSerializer = _miragejs.RestSerializer.extend({
    /**
     * The property name for the primary key for mirage and ember data is normally `id`. This allows you
     * to specify what that property name should be in the JSON.
     */
    primaryKey: 'id',
    /**
     * Transforms follow the format of ember data serializer attrs as follows
     *
     * {
     *   property: {    // property would be the name of the property in the mirage store
     *      key: 'externalKey',   // externalKey would be the name in the JSON
     *      serialize: 'ids',     // how should this property be serialized and deserialized
     *      deserialize: 'ids'    // the default is 'ids' that is the id of the releation
     *                            // or 'records', that is, embed the full record instead of the id
     *                            // or false, do not serialize or deserialize as applied
     *   }
     * }
     *
     * These transforms will be created from the attrs on the corresponding serializer in ember data much like
     * the models for mirage are created from the models in in ember date. If the transforms key is specified, it
     * will overlay the definition created from the serializer key for key. That is you could override some
     * of the transform definition.
     */
    transforms: undefined,
    // resolved transforms
    _transforms: undefined,
    // These are the defaults
    // include: []
    keyForId: function keyForId() {
      return this.primaryKey;
    },
    getKeysForIncluded: function getKeysForIncluded() {
      return (0, _lodashEs.isFunction)(this.include) ? this.include(this.request, this.primaryResource) : this.include;
    },
    getTransforms: function getTransforms() {
      return this.transforms || {};
    },
    getResolvedTransforms: function getResolvedTransforms() {
      if (!this._resolvedTransforms) {
        this._resolvedTransforms = {
          serialize: {},
          normalize: {}
        };
      }
      return this._resolvedTransforms;
    },
    getTransformForSerialize: function getTransformForSerialize(key) {
      var resolvedTransforms = this.getResolvedTransforms();
      var transforms = this.getTransforms();
      if (!resolvedTransforms.serialize[key]) {
        var transform = typeof transforms[key] === 'string' ? {
          key: transforms[key]
        } : Object.assign({}, transforms[key]);
        resolvedTransforms.serialize[key] = Object.assign({
          key: key,
          serialize: 'ids',
          deserialize: 'ids'
        }, transform);
      }
      return resolvedTransforms.serialize[key];
    },
    getTransformForNormalize: function getTransformForNormalize(key) {
      var resolvedTransforms = this.getResolvedTransforms();
      if (!resolvedTransforms.normalize[key]) {
        var transforms = this.getTransforms();
        var foundKey;
        var foundTransform = Object.keys(transforms).find(function (item) {
          foundKey = item;
          return transforms[item].key === key;
        });
        var transform = foundTransform ? Object.assign({}, transforms[foundKey], {
          key: foundKey
        }) : {
          key: key,
          serialize: 'ids',
          deserialize: 'ids'
        };
        resolvedTransforms.normalize[key] = transform;
      }
      return resolvedTransforms.normalize[key];
    },
    /**
     *
     * @param model
     * @param removeForeignKeys
     * @param didSerialize
     * @returns {*}
     * @private
     */
    _hashForModel: function _hashForModel(model, removeForeignKeys) {
      var _this = this;
      var didSerialize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var attrs = this._attrsForModel(model);
      var newDidSerialize = Object.assign({}, didSerialize);
      newDidSerialize[model.modelName] = newDidSerialize[model.modelName] || {};
      newDidSerialize[model.modelName][model.id] = true;
      model.associationKeys.forEach(function (key) {
        var transform = _this.getTransformForSerialize(key);
        if (transform.serialize) {
          var associatedResource = model[key];
          var serializeOption = transform.serialize;
          if (associatedResource && Ember.get(newDidSerialize, "".concat(associatedResource.modelName, ".").concat(associatedResource.id))) {
            // force it to IDS if we already have serialized it to prevent recursion
            // TODO: However is the end system wants records, we need to send records, so this really should be do records, dont resurse
            serializeOption = 'ids';
          }
          if (serializeOption === 'records') {
            var _this$getHashForResou = _this.getHashForResource(associatedResource, false, newDidSerialize, true),
              _this$getHashForResou2 = (0, _slicedToArray2.default)(_this$getHashForResou, 1),
              associatedResourceHash = _this$getHashForResou2[0];
            var formattedKey = _this._keyForProperty(key) || _this.isCollection(associatedResource) ? _this.keyForRelationship(key) : _this.keyForEmbeddedRelationship(key);
            attrs[formattedKey] = associatedResourceHash;
          } else {
            var _formattedKey = _this._keyForProperty(key) || _this.keyForRelationshipIds(key);
            if (_this.isCollection(associatedResource)) {
              attrs[_formattedKey] = model["".concat(_this._container.inflector.singularize(key), "Ids")];
            } else {
              attrs[_formattedKey] = model["".concat(_this._container.inflector.singularize(key), "Id")];
            }
          }
        }
      });
      return attrs;
    },
    _keyForProperty: function _keyForProperty(attr) {
      var transform = this.getTransformForSerialize(attr);
      return transform.key;
    },
    keyForAttribute: function keyForAttribute(attr) {
      if (attr === 'id') {
        return this.keyForId();
      }
      return this._keyForProperty(attr) || _miragejs.RestSerializer.prototype.keyForAttribute.apply(this, arguments);
    },
    keyForRelationship: function keyForRelationship(type) {
      return this._keyForProperty(type) || _miragejs.RestSerializer.prototype.keyForRelationship.apply(this, arguments);
    },
    keyForEmbeddedRelationship: function keyForEmbeddedRelationship(attributeName) {
      return this._keyForProperty(attributeName) || _miragejs.RestSerializer.prototype.keyForEmbeddedRelationship.apply(this, arguments);
    },
    keyForRelationshipIds: function keyForRelationshipIds(type) {
      return this._keyForProperty(type) || _miragejs.RestSerializer.prototype.keyForRelationshipIds.apply(this, arguments);
    },
    keyForForeignKey: function keyForForeignKey(relationshipName) {
      return this._keyForProperty(relationshipName) || _miragejs.RestSerializer.prototype.keyForForeignKey.apply(this, arguments);
    },
    normalize: function normalize(payload) {
      var _this2 = this;
      // was it not wrapped when serialized?
      if (this.root === false) {
        var p = {};
        p[this.type] = payload;
        payload = p;
      }
      var type = Object.keys(payload)[0];
      var attrs = payload[type];
      var modelName = (0, _miragejs._utilsInflectorCamelize)(type);
      var modelClass = this.schema.modelClassFor(modelName);
      var belongsToAssociations = modelClass.belongsToAssociations,
        hasManyAssociations = modelClass.hasManyAssociations;
      var belongsToKeys = Object.keys(belongsToAssociations);
      var hasManyKeys = Object.keys(hasManyAssociations);
      var jsonApiPayload = {
        data: {
          type: this._container.inflector.pluralize(type),
          attributes: {}
        }
      };
      if (attrs[this.primaryKey]) {
        jsonApiPayload.data.id = attrs[this.primaryKey];
      }
      var relationships = {};
      Object.keys(attrs).forEach(function (attrKey) {
        if (attrKey !== _this2.primaryKey) {
          var transform = _this2.getTransformForNormalize(attrKey);
          var key = transform.key || attrKey;
          if (_this2.normalizeIds) {
            if (belongsToKeys.includes(key)) {
              var association = belongsToAssociations[key];
              var associationModel = association.modelName;
              relationships[(0, _miragejs._utilsInflectorDasherize)(key)] = {
                data: {
                  type: associationModel,
                  id: attrs[attrKey]
                }
              };
            } else if (hasManyKeys.includes(key)) {
              var _association = hasManyAssociations[key];
              var _associationModel = _association.modelName;
              var data = attrs[attrKey].map(function (id) {
                return {
                  type: _associationModel,
                  id: id
                };
              });
              relationships[(0, _miragejs._utilsInflectorDasherize)(key)] = {
                data: data
              };
            } else {
              jsonApiPayload.data.attributes[(0, _miragejs._utilsInflectorDasherize)(key)] = attrs[attrKey];
            }
          } else {
            jsonApiPayload.data.attributes[(0, _miragejs._utilsInflectorDasherize)(key)] = attrs[attrKey];
          }
        }
      });
      if (Object.keys(relationships).length) {
        jsonApiPayload.data.relationships = relationships;
      }
      return jsonApiPayload;
    }
  });
  var _default = _exports.default = EmberDataSerializer;
});