define("ember-cli-materialize/components/-md-fixed-btn-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actionArgs: null,
    large: true,
    actions: {
      fireButtonAction: function fireButtonAction() {
        var actionArgs = this.get('actionArgs');
        if (actionArgs) {
          this.sendAction('action', actionArgs || null);
        } else {
          this.sendAction('action');
        }
      }
    },
    _btnClassString: Ember.computed('btnClass', function () {
      return "".concat(this.get('btnClass'), " btn-floating ").concat(this.get('large') ? 'btn-large' : '');
    })
  });
});