define("ember-composable-helpers/helpers/inc", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.inc = inc;
  function inc(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      step = _ref2[0],
      val = _ref2[1];
    if (Ember.isEmpty(val)) {
      val = step;
      step = undefined;
    }
    val = Number(val);
    if (isNaN(val)) {
      return;
    }
    if (step === undefined) {
      step = 1;
    }
    return val + step;
  }
  var _default = _exports.default = Ember.Helper.helper(inc);
});