define("ember-composable-helpers/helpers/append", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.append = append;
  _exports.default = void 0;
  function append(_ref) {
    var _ref3;
    var _ref2 = (0, _toArray2.default)(_ref),
      arrays = _ref2.slice(0);
    return (_ref3 = []).concat.apply(_ref3, (0, _toConsumableArray2.default)(arrays));
  }
  var _default = _exports.default = Ember.Helper.helper(append);
});