define("in-repo-pin-addon/components/g-map-addons/pin", ["exports", "in-repo-pin-addon/templates/components/g-map-addons/pin"], function (_exports, _pin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _pin.default,
    _type: 'pin',
    _addComponent: function _addComponent() {},
    _updateComponent: function _updateComponent() {}
  });
});