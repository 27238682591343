define("ember-google-maps/components/g-map/map-component", ["exports", "ember-google-maps/utils/options-and-events"], function (_exports, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MapComponentAPI = MapComponentAPI;
  _exports.MapComponentLifecycleEnum = void 0;
  _exports.combine = combine;
  _exports.default = void 0;
  function combine(base, extra) {
    return Object.defineProperties(base, Object.getOwnPropertyDescriptors(extra));
  }
  function MapComponentAPI(source) {
    return {
      get map() {
        return source.map;
      },
      get mapComponent() {
        return source.mapComponent;
      },
      get isInitialized() {
        return source.isInitialized;
      },
      actions: {
        update: function update() {
          return source._updateComponent;
        }
      }
    };
  }
  var NOT_READY = 1,
    IN_PROGRESS = 2,
    READY = 3;
  var MapComponentLifecycleEnum = _exports.MapComponentLifecycleEnum = {
    NOT_READY: NOT_READY,
    IN_PROGRESS: IN_PROGRESS,
    READY: READY
  };

  /**
   * @class MapComponent
   * @module ember-google-maps/components/g-map/map-component
   * @namespace GMap
   * @extends Component
   */
  var MapComponent = _exports.default = Ember.Component.extend({
    tagName: '',
    _type: undefined,
    mapComponentLifecycle: NOT_READY,
    /* Options and events */_createOptions: function _createOptions(options) {
      return options;
    },
    _createEvents: function _createEvents(events) {
      return events;
    },
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)(_optionsAndEvents.ignoredOptions),
    _options: Ember.computed.readOnly('_optionsAndEvents.options'),
    _events: Ember.computed.readOnly('_optionsAndEvents.events'),
    /* Lifecycle hooks */init: function init() {
      this._super.apply(this, arguments);
      (true && !(typeof this._type !== 'undefined') && Ember.assert('You must set a _type property on the map component.', typeof this._type !== 'undefined'));
      this._registrationType = this._pluralType || "".concat(this._type, "s");
      this.isInitialized = Ember.RSVP.defer();

      /**
       * An array of bound event listeners. Call `remove` on each before
       * destroying the component.
       */
      this._eventListeners = new Map();
      this.publicAPI = MapComponentAPI(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._internalAPI._registerComponent(this._registrationType, this.publicAPI);
      this._updateOrAddComponent();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._updateOrAddComponent();
    },
    willDestroyElement: function willDestroyElement() {
      var _this$mapComponent, _this$mapComponent$se;
      this._super.apply(this, arguments);
      this._eventListeners.forEach(function (remove) {
        return remove();
      });
      (_this$mapComponent = this.mapComponent) === null || _this$mapComponent === void 0 ? void 0 : (_this$mapComponent$se = _this$mapComponent.setMap) === null || _this$mapComponent$se === void 0 ? void 0 : _this$mapComponent$se.call(_this$mapComponent, null);
      this._internalAPI._unregisterComponent(this._registrationType, this.publicAPI);
    },
    _updateOrAddComponent: function _updateOrAddComponent() {
      var _this = this;
      var options, events;
      switch (this.mapComponentLifecycle) {
        case READY:
          options = this._createOptions(Ember.get(this, '_options'));
          events = this._createEvents(Ember.get(this, '_events'));
          this._updateComponent(this.mapComponent, options, events);
          break;
        case IN_PROGRESS:
          break;
        // PASS

        case NOT_READY:
          if (typeof this.map === 'undefined') {
            break;
          }
          this.mapComponentLifecycle = IN_PROGRESS;
          options = this._createOptions(Ember.get(this, '_options'));
          events = this._createEvents(Ember.get(this, '_events'));
          Ember.RSVP.resolve().then(function () {
            return _this._addComponent(options, events);
          }).then(function (mapComponent) {
            return _this._didAddComponent(mapComponent, options, events);
          }).then(function () {
            _this.isInitialized.resolve();
            _this.mapComponentLifecycle = READY;
          }).catch(function () {
            _this.mapComponentLifecycle = NOT_READY;
          });
          break;
      }
    },
    /* Map component hooks */
    /**
     * Run when the map component is first initialized. Normally this happens as
     * soon as the map is ready.
     *
     * @method _addComponent
     * @return
     */
    _addComponent: function _addComponent() {
      (true && !(false) && Ember.assert('Map components must implement the _addComponent hook.'));
      return Ember.RSVP.reject();
    } /* options, events */,
    /**
     * Run after the map component has been initialized. This hook should be used
     * to register events, etc.
     *
     * @method _didAddComponent
     * @return
     */
    _didAddComponent: function _didAddComponent(mapComponent, options, events) {
      var _this2 = this;
      var payload = {
        map: this.map,
        publicAPI: this.publicAPI
      };
      (0, _optionsAndEvents.addEventListeners)(mapComponent, events, payload).forEach(function (_ref) {
        var name = _ref.name,
          remove = _ref.remove;
        return _this2._eventListeners.set(name, remove);
      });
      return Ember.RSVP.resolve();
    },
    /**
     * Run when any of the attributes or watched options change.
     *
     * @method _updateComponent
     * @return
     */
    _updateComponent: function _updateComponent(mapComponent, options /* , events */) {
      mapComponent.setOptions(options);
    }
  });
});