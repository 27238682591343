define("ember-math-helpers/helpers/expm1", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.expm1 = expm1;
  /**
   * Executes `Math.expm1` on the number passed to the helper.
   *
   * ```hbs
   * {{expm1 a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.expm1`
   * @return {number} The expm1 of the passed number
   */
  function expm1(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      number = _ref2[0];
    return Math.expm1(number);
  }
  var _default = _exports.default = Ember.Helper.helper(expm1);
});