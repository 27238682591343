define("liquid-fire/templates/components/liquid-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+UnSpvQM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"ready\"],null]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-sync.hbs"
    }
  });
});