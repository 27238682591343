define("ember-light-table/templates/components/lt-scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P2f4AY1O",
    "block": "{\"symbols\":[\"scrollbar\",\"&default\"],\"statements\":[[5,[27,[26,7,\"BlockHead\"],[]],[[27,[26,6,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],null,[[\"classNames\",\"autoHide\",\"horizontal\",\"vertical\",\"scrollToY\",\"onScrollY\"],[\"lt-scrollable\",[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,2,[[27,[24,1],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[16,2,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onScrollY\",\"scrollTo\",\"vertical\",\"horizontal\",\"autoHide\",\"ember-scrollable\",\"virtualScrollbar\",\"if\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-scrollable.hbs"
    }
  });
});