define("ember-cli-materialize/components/md-fixed-btns", ["exports", "ember-cli-materialize/components/-md-fixed-btn-base", "ember-cli-materialize/templates/components/md-fixed-btns"], function (_exports, _mdFixedBtnBase, _mdFixedBtns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mdFixedBtnBase.default.extend({
    layout: _mdFixedBtns.default,
    classNames: ['md-fixed-btns', 'fixed-action-btn']
  });
});