define("@kockpit/ember-gantt/templates/components/gantt-milestone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "B1HhO8BT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"gantt-line-milestone-icon\",null],[10],[11],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"gantt-line-milestone-title\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,1,null],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",true],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formattedDate\",\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@kockpit/ember-gantt/templates/components/gantt-milestone.hbs"
    }
  });
});