define("ember-cli-materialize/components/md-tabs", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composability/mixins/parent-component-support", "ember-cli-materialize/templates/components/md-tabs"], function (_exports, _slicedToArray2, _parentComponentSupport, _mdTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_parentComponentSupport.default, {
    layout: _mdTabs.default,
    classNames: ['materialize-tabs', 'row'],
    composableChildrenDebounceTime: 1,
    content: null,
    numTabs: Ember.computed.alias('composableChildren.length'),
    optionValuePath: 'id',
    optionLabelPath: 'title',
    colWidth: 2,
    selected: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._updateIndicatorPosition(false);
    },
    _indicatorUpdater: Ember.observer('selected', 'content.[]', 'composableChildren.[]', function () {
      Ember.run.debounce(this, this._updateIndicatorPosition, 100);
    }),
    tabComponents: function tabComponents() {
      return Ember.A(this.get('composableChildren')) || Ember.A();
    },
    _updateIndicatorPosition: function _updateIndicatorPosition() {
      var _this = this;
      var animate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (!this.element) {
        return;
      }
      var _filter = (this.get('composableChildren') || []).filter(function (item) {
          return Ember.get(item, 'value') === _this.get('selected');
        }),
        _filter2 = (0, _slicedToArray2.default)(_filter, 1),
        tabComponent = _filter2[0];
      var tabSetRect = this.element.getBoundingClientRect();
      if (tabComponent) {
        var tabRect = tabComponent.element.getBoundingClientRect();
        var cssParams = {
          left: tabRect.left - tabSetRect.left,
          right: tabSetRect.right - tabRect.right
        };
        if (!animate) {
          this.$('.indicator').css(cssParams);
        } else {
          this.$('.indicator1').velocity(cssParams, {
            duration: 150
          });
          this.$('.indicator2').velocity(cssParams, {
            duration: 150,
            delay: 40
          });
        }
      }
    },
    _content: Ember.computed('content.[]', 'optionLabelPath', 'optionValuePath', function () {
      var labelPath = this.get('optionLabelPath');
      var valuePath = this.get('optionValuePath');
      return Ember.A((this.get('content') || []).map(function (contentItem) {
        return {
          id: contentItem[valuePath],
          title: contentItem[labelPath]
        };
      }));
    })
  });
});