define("ember-composable-helpers/helpers/chunk", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chunk = chunk;
  _exports.default = void 0;
  var max = Math.max,
    ceil = Math.ceil;
  function chunk(num, array) {
    var integer = parseInt(num, 10);
    var size = max(integer, 0);
    var length = 0;
    if (Ember.isArray(array)) {
      length = array.length;
    }
    array = (0, _asArray.default)(array);
    if (!length || size < 1) {
      return [];
    } else {
      var index = 0;
      var resultIndex = -1;
      var result = new Array(ceil(length / size));
      while (index < length) {
        result[++resultIndex] = array.slice(index, index += size);
      }
      return result;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      num = _ref2[0],
      array = _ref2[1];
    return chunk(num, array);
  });
});