define("ember-metrics/utils/remove-from-dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removeFromDOM;
  function removeFromDOM(script) {
    document.querySelectorAll(script).forEach(function (el) {
      el.parentElement.removeChild(el);
    });
  }
});