define("ember-welcome-page/components/welcome-page", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-welcome-page/templates/components/welcome-page"], function (_exports, _slicedToArray2, _welcomePage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _welcomePage.default,
    isCurrent: Ember.computed(function () {
      var stableRegex = /^\d+\.\d+\.\d+$/;
      return !stableRegex.test(Ember.VERSION);
    }),
    canAngleBracket: Ember.computed(function () {
      return true;
    }),
    isModuleUnification: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config && config.isModuleUnification;
    }),
    rootURL: Ember.computed(function () {
      var config = Ember.getOwner(this).factoryFor('config:environment');
      if (config) {
        return config.class.rootURL;
      } else {
        return '/';
      }
    }),
    emberVersion: Ember.computed('isCurrent', function () {
      var isCurrent = Ember.get(this, 'isCurrent');
      if (isCurrent) {
        return 'current';
      } else {
        var _Ember$VERSION$split = Ember.VERSION.split("."),
          _Ember$VERSION$split2 = (0, _slicedToArray2.default)(_Ember$VERSION$split, 2),
          major = _Ember$VERSION$split2[0],
          minor = _Ember$VERSION$split2[1];
        return "".concat(major, ".").concat(minor, ".0");
      }
    })
  });
});