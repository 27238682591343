define("ember-google-maps/components/-private-api/warn-missing-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Throw an assertion to warn the user when they call a component that isn't
   * included in the build. In development, we replace the excluded component with
   * this one instead.
   */
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var name = this._name;
      var message = "\nEmber Google Maps couldn't find a map component called \"".concat(name, "\"!\n\nIf you are excluding certain map components from your app in your ember-cli-build.js, make sure to\ninclude \"").concat(name, "\".\n\nLearn more at: https://ember-google-maps.sandydoo.me/docs/getting-started");
      (true && !(false) && Ember.assert(message));
    }
  });
});