define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VRbNq7Ft",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,8,\"BlockHead\"],[]],[[27,[26,7,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"ember-power-select-search\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"input\",true],[12,\"autocomplete\",\"off\",null],[12,\"autocorrect\",\"off\",null],[12,\"autocapitalize\",\"off\",null],[12,\"spellcheck\",\"false\",null],[12,\"role\",\"combobox\",null],[12,\"class\",\"ember-power-select-search-input\",null],[13,\"value\",[27,[26,0,\"AppendSingleId\"],[\"searchText\"]],null],[13,\"aria-controls\",[27,[26,1,\"AppendSingleId\"],[]],null],[13,\"placeholder\",[27,[26,2,\"AppendSingleId\"],[]],null],[13,\"oninput\",[27,[26,3,\"AppendSingleId\"],[]],null],[13,\"onfocus\",[27,[26,4,\"AppendSingleId\"],[]],null],[13,\"onblur\",[27,[26,5,\"AppendSingleId\"],[]],null],[13,\"onkeydown\",[31,442,6,[27,[26,6,\"CallHead\"],[]],[[27,[24,0],[]],\"onKeydown\"],null],null],[12,\"type\",\"search\",null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"select\",\"listboxId\",\"searchPlaceholder\",\"onInput\",\"onFocus\",\"onBlur\",\"action\",\"searchEnabled\",\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
    }
  });
});