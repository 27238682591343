define("ember-cli-materialize/templates/components/md-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sXRighLv",
    "block": "{\"symbols\":[\"spinnerClassName\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]]]],null],[13,\"style\",[27,[26,1,\"AppendSingleId\"],[]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[13,\"class\",[32,[[27,[24,1],[]]]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"circle-clipper left\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"div\",true],[12,\"class\",\"circle\",null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[9,\"div\",true],[12,\"class\",\"gap-patch\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"div\",true],[12,\"class\",\"circle\",null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[9,\"div\",true],[12,\"class\",\"circle-clipper right\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"div\",true],[12,\"class\",\"circle\",null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"barClassName\",\"barStyle\",\"isBarType\",\"if\",\"spinnerClassNames\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-loader.hbs"
    }
  });
});