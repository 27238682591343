define("@kockpit/ember-gantt/components/gantt-line-inline-childs", ["exports", "@kockpit/ember-gantt/utils/date-util", "@kockpit/ember-gantt/templates/components/gantt-line-inline-childs"], function (_exports, _dateUtil, _ganttLineInlineChilds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   With the inline-childs component, you can show multiple lines in one line. Use it as sub-component of the line component.
  
   ### Usage
   Use as a inline component passing `childLines` array, each having dateStart,dateEnd and color attributes.
   The component calculates the overlapping or empty periods for coloring.
   components as children:
   ```handlebars
    {{#gantt-chart dayWidth=10 as |chart|}}
  
      {{#each projects as |p|}}
  
        {{#chart.line dateStart=p.dateStart dateEnd=p.dateEnd as |line|}}
  
          {{line.inlineChilds childLines=p.inlineChilds }} {{! <-- this }}
  
        {{/chart.line}}
      {{/each}}
  
    {{/gantt-chart}}
   ```
  
   @class GanttLineInlineChilds
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _ganttLineInlineChilds.default,
    classNames: ['gantt-line-inline-childs'],
    chart: null,
    parentLine: null,
    /**
     * Stripe width for overlapping areas
     *
     * @property stripeWidth
     * @argument stripeWidth
     * @type int
     * @default 3
     * @public
     */
    stripeWidth: 3,
    /**
     * Pass a childs array as "childLines" with object having the attributes {dateStart, dateEnd, color}
     *
     * @property childLines
     * @argument childLines
     * @type array
     * @default null
     * @public
     */
    childLines: Ember.A(),
    /**
     * calculated periods for rendering in template
     *
     * @property periods
     * @type array
     * @default null
     * @protected
     */
    periods: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.calculatePeriods();
    },
    reloadPeriods: Ember.observer('parentLine.{dateStart,dateEnd,dayWidth}', 'childLines.@each.{dateStart,dateEnd,color}', function () {
      Ember.run.throttle(this, this.calculatePeriods, 50);
    }),
    // Calculate period-segments from child dateStart/End (using dateUtil)
    // updates 'periods' attribute on component
    calculatePeriods: function calculatePeriods() {
      var _this = this;
      // go through all jobs and generate compound child elements
      var chart = Ember.get(this, 'chart'),
        childs = Ember.get(this, 'childLines'),
        start = Ember.get(this, 'parentLine._start'),
        end = Ember.get(this, 'parentLine._end');

      // generate period segments
      var periods = _dateUtil.default.mergeTimePeriods(childs, start, end);

      // calculate width of segments
      if (periods && periods.length > 0) {
        periods.forEach(function (period) {
          period.width = chart.dateToOffset(period.dateEnd, period.dateStart, true);
          period.background = _this.getBackgroundStyle(period.childs);
          period.style = Ember.String.htmlSafe("width:".concat(period.width, "px;background:").concat(period.background, ";"));
        });
      }
      Ember.set(this, 'periods', periods);
    },
    //Creates a background style from childs[n].color attributes
    // -> transparent for no childs, color from 1 child, striped background for n childs
    getBackgroundStyle: function getBackgroundStyle(childs) {
      if (!Ember.isArray(childs) || childs.length === 0) {
        return 'transparent';
      }
      var colors = Ember.A(Ember.A(childs).getEach('color'));
      colors = colors.uniq(); // every color only once!
      colors = colors.sort(); // assure color-order always the same
      colors = colors.filter(function (color) {
        return !Ember.isEmpty(color);
      }); // remove empty color strings

      // single-color
      if (colors.length === 1) {
        return colors[0];
      }

      // multi-color
      var background = 'repeating-linear-gradient(90deg,'; // or 180? ;)
      var pxOffset = 0;
      var stripeWidth = Ember.get(this, 'stripeWidth');
      colors.forEach(function (color) {
        var nextOffset = pxOffset + stripeWidth;
        background += "".concat(color, " ").concat(pxOffset, "px,").concat(color, " ").concat(nextOffset, "px,");
        pxOffset = nextOffset;
      });
      background = background.substring(0, background.length - 1) + ')';
      return background;
    }
  });
});