define("ember-light-table/templates/components/cells/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Z2BDX49q",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,8,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[\"cellComponent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,33,9,[27,[26,7,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[27,[26,6,\"Expression\"],[]],[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"rawValue\",\"row\",\"column\",\"table\",\"extra\",\"tableActions\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/cells/base.hbs"
    }
  });
});