define("ember-google-maps/components/g-map/marker", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/marker", "ember-google-maps/utils/helpers"], function (_exports, _objectSpread2, _mapComponent, _marker, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A wrapper for the google.maps.Marker class.
   *
   * @class Marker
   * @namespace GMap
   * @module ember-google-maps/components/g-map/marker
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _marker.default,
    tagName: '',
    _type: 'marker',
    position: Ember.computed('lat', 'lng', _helpers.position),
    _createOptions: function _createOptions(options) {
      return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, options), {}, {
        map: this.map,
        position: Ember.get(this, 'position')
      });
    },
    _addComponent: function _addComponent(options) {
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', new google.maps.Marker(options)));
    }
  });
});