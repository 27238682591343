define("ember-cli-materialize/components/md-fixed-btn", ["exports", "ember-cli-materialize/components/-md-fixed-btn-base", "ember-cli-materialize/templates/components/md-fixed-btn"], function (_exports, _mdFixedBtnBase, _mdFixedBtn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mdFixedBtnBase.default.extend({
    layout: _mdFixedBtn.default,
    tagName: 'li',
    classNames: ['md-fixed-btn']
  });
});