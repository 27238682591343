define("ember-cli-materialize/templates/components/md-default-collection-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MMiOj4QI",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h4\",true],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11]],\"hasEval\":false,\"upvars\":[\"header\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-default-collection-header.hbs"
    }
  });
});