define("@kockpit/ember-gantt/components/gantt-timeline", ["exports", "@kockpit/ember-gantt/utils/date-util", "@kockpit/ember-gantt/templates/components/gantt-timeline"], function (_exports, _dateUtil, _ganttTimeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _ganttTimeline.default,
    classNames: ['gantt-timeline'],
    chart: null,
    viewStartDate: Ember.computed.alias('chart.viewStartDate'),
    viewEndDate: Ember.computed.alias('chart.viewEndDate'),
    // in-page styles
    dayWidth: Ember.computed.alias('chart.dayWidth'),
    dayWidthPx: Ember.computed('dayWidth', function () {
      return Ember.String.htmlSafe("".concat(Ember.get(this, 'dayWidth'), "px"));
    }),
    cwWidthPx: Ember.computed('dayWidth', function () {
      var width = Ember.get(this, 'dayWidth') * 7;
      return Ember.String.htmlSafe("".concat(width, "px"));
    }),
    // dayClasses - special day classes (e.g. today)
    showToday: Ember.computed.alias('chart.showToday'),
    dayClasses: Ember.computed.alias('chart.dayClasses'),
    specialDays: Ember.computed('dayClasses', function () {
      // special timestamp index
      var days = {};
      Ember.get(this, 'dayClasses').forEach(function (day) {
        days[_dateUtil.default.getNewDate(day.date).getTime()] = day;
      });
      return days;
    }),
    // sticky header
    headerElement: null,
    headerTitle: Ember.computed.alias('chart.headerTitle'),
    scrollLeft: Ember.computed.alias('chart.scrollLeft'),
    stickyOffset: Ember.computed.alias('chart.stickyOffset'),
    stickyStyle: Ember.String.htmlSafe(''),
    stickyPlaceholderStyle: Ember.String.htmlSafe(''),
    isSticky: false,
    // use document scroll event to check for sticky
    init: function init() {
      this._super.apply(this, arguments);
      this._handleDocScroll = Ember.run.bind(this, this.checkSticky);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.set(this, 'headerElement', this.element.querySelector('.gantt-chart-header'));

      // init sticky
      if (!Ember.isNone(Ember.get(this, 'stickyOffset'))) {
        document.addEventListener('scroll', this._handleDocScroll);
      }

      // init timeline scale
      if (Ember.get(this, 'autoTimeline')) {
        this.evaluateTimlineElements();
      }
    },
    willDestroyelement: function willDestroyelement() {
      this._super.apply(this, arguments);
      if (!Ember.isNone(Ember.get(this, 'stickyOffset'))) {
        document.removeEventListener('scroll', this._handleDocScroll);
      }
    },
    // STICKY
    // -------------------
    checkSticky: function checkSticky() {
      var headerElement = Ember.get(this, 'headerElement');
      var chartElement = Ember.get(this, 'chart.element');
      var offset = 0;
      var chartBottom = 1;
      if (headerElement && chartElement) {
        offset = headerElement.getBoundingClientRect().top;
        var chartOffset = chartElement.getBoundingClientRect();
        chartBottom = chartOffset.top + chartOffset.height - 100 || 1;
      }
      if (!Ember.get(this, 'isSticky') && offset < Ember.get(this, 'stickyOffset') && chartBottom >= 100) {
        this.makeSticky();
      } else if (Ember.get(this, 'isSticky') && (offset > Ember.get(this, 'stickyOffset') || chartBottom < 100)) {
        this.resetSticky();
      }
    } /*e*/,
    onResize: Ember.observer('ganttWidth', function () {
      this.updateSticky();
    }),
    makeSticky: function makeSticky() {
      Ember.set(this, 'isSticky', true);
      this.updateSticky();
    },
    updateSticky: function updateSticky() {
      if (Ember.get(this, 'isSticky')) {
        var stickyOffset = Ember.get(this, 'stickyOffset');
        var ganttWidth = Ember.get(this, 'chart.ganttWidth');
        var ganttLeft = Ember.get(this, 'chart.element').getBoundingClientRect().left;
        var headerHeight = Ember.get(this, 'headerElement.offsetHeight');
        Ember.set(this, 'stickyStyle', Ember.String.htmlSafe("top:".concat(stickyOffset, "px;left:").concat(ganttLeft, "px;width:").concat(ganttWidth, "px;height:").concat(headerHeight, "px;")));
        Ember.set(this, 'stickyPlaceholderStyle', Ember.String.htmlSafe("height:".concat(headerHeight, "px;")));
      }
    },
    resetSticky: function resetSticky() {
      Ember.set(this, 'isSticky', false);
      Ember.set(this, 'stickyStyle', Ember.String.htmlSafe(''));
      Ember.set(this, 'stickyPlaceholderStyle', Ember.String.htmlSafe(''));
    },
    scaleWidth: 0,
    // is calculated in scale-grid generation

    // timeline scroll needs to be manually adjusted, as position-fixed does not inherit scrolling
    scaleStyle: Ember.computed('scaleWidth', 'isSticky', 'scrollLeft', function () {
      // total width
      var style = "width:".concat(Ember.get(this, 'scaleWidth'), "px;");
      if (Ember.get(this, 'isSticky')) {
        style += "left:-".concat(Ember.get(this, 'scrollLeft'), "px;");
      }
      return Ember.String.htmlSafe(style);
    }),
    // Activate automatical timeline view adjustments, based on dayWidth
    autoTimeline: Ember.computed.alias('chart.autoTimeline'),
    timelineDay: Ember.computed.alias('chart.timelineDay'),
    timelineCW: Ember.computed.alias('chart.timelineCW'),
    timelineMonth: Ember.computed.alias('chart.timelineMonth'),
    timelineMonthShort: Ember.computed.alias('chart.timelineMonthShort'),
    timelineYear: Ember.computed.alias('chart.timelineYear'),
    autoViewObs: Ember.observer('dayWidth', 'autoTimeline', function () {
      if (Ember.get(this, 'autoTimeline')) {
        this.evaluateTimlineElements();
      }
    }),
    evaluateTimlineElements: function evaluateTimlineElements() {
      var dayWidth = Ember.get(this, 'dayWidth');
      var views = {
        timelineDay: true,
        timelineCW: true,
        timelineMonth: true,
        timelineMonthShort: false,
        timelineYear: false
      };
      if (dayWidth < 20) {
        // cw's instead of days
        views.timelineDay = false;
        views.timelineCW = true;
      }
      if (dayWidth < 15) {// months
        // views.timelineMonth = true;
      }
      if (dayWidth < 10) {
        // months (small)
        views.timelineYear = true;
        views.timelineMonthShort = true;
      }
      if (dayWidth < 5) {
        // year/month
        views.timelineYear = true;
        views.timelineCW = false;
      }
      if (dayWidth < 2) {
        // year only
        views.timelineYear = true;
        views.timelineMonth = false;
      }
      Ember.setProperties(this, views);
    },
    calculateScaleWidth: function calculateScaleWidth(dayWidth, start, end) {
      return dayWidth * parseInt(_dateUtil.default.diffDays(start, end, true));
    },
    // FIXME: workaround for ember/no-side-effects
    updateScaleWidth: function updateScaleWidth(scaleWidth) {
      Ember.set(this, 'scaleWidth', scaleWidth);
    },
    timelineScale: Ember.computed('viewStartDate', 'viewEndDate', 'dayWidth', 'specialDays', 'chart.ganttWidth', function () {
      var chart = Ember.get(this, 'chart');
      var start = _dateUtil.default.getNewDate(Ember.get(this, 'viewStartDate')),
        end = _dateUtil.default.getNewDate(Ember.get(this, 'viewEndDate')),
        dayWidth = Ember.get(this, 'dayWidth');
      if (!start || !end || !(start < end)) {
        return [];
      }

      // calculate total scale width
      var scaleWidth = this.calculateScaleWidth(dayWidth, start, end);
      if (scaleWidth < Ember.get(chart, 'ganttWidth')) {
        end = chart.offsetToDate(Ember.get(chart, 'ganttWidth') * 1.5);
        scaleWidth = this.calculateScaleWidth(dayWidth, start, end);
      }
      this.updateScaleWidth(scaleWidth);
      return {
        months: _dateUtil.default.monthsInPeriod(start, end, dayWidth, Ember.get(this, 'specialDays')),
        calendarWeeks: Ember.get(this, 'timelineCW') ? _dateUtil.default.calendarWeeksInPeriod(start, end, dayWidth) : null,
        years: Ember.get(this, 'timelineYear') ? _dateUtil.default.yearsInPeriod(start, end, dayWidth) : null
      };
    })
  });
});