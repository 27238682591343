define("ember-google-maps/helpers/g-map/compute", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.gMapCompute = gMapCompute;
  // Same as ember-composable-helpers 'compute'.
  function gMapCompute(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
      action = _ref2[0],
      params = _ref2.slice(1);
    return action.apply(void 0, (0, _toConsumableArray2.default)(params));
  }
  var _default = _exports.default = Ember.Helper.helper(gMapCompute);
});