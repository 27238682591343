define("ember-google-maps/templates/components/g-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+meghVKN",
    "block": "{\"symbols\":[\"gMap\",\"addons\",\"&attrs\",\"&default\"],\"statements\":[[5,[27,[26,6,\"BlockHead\"],[]],[[31,11,4,[27,[26,5,\"CallHead\"],[]],null,[[\"canvas\",\"publicAPI\",\"map\",\"_internalAPI\"],[[31,28,9,[27,[26,4,\"CallHead\"],[]],[\"g-map/canvas\"],[[\"id\",\"classNames\",\"_internalAPI\"],[[27,[24,0],[\"mapId\"]],[27,[24,0],[\"classNames\"]],[27,[24,0],[\"_internalAPI\"]]]]],[27,[24,0],[\"publicAPI\"]],[27,[24,0],[\"map\"]],[27,[24,0],[\"_internalAPI\"]]]]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],null,[[\"gMap\",\"map\",\"_internalAPI\"],[[27,[24,0],[\"gMap\"]],[27,[24,1],[\"map\"]],[27,[24,1],[\"_internalAPI\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,366,13,[27,[26,1,\"CallHead\"],[]],[[31,381,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,0],[\"_updateGMap\"]]],null],[27,[24,1],[]],[27,[24,2],[]]],null]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[29,[24,4]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[7,[27,[24,1],[\"canvas\"]],[[15,3]],[[],[]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[16,4,[[27,[24,0],[\"gMap\"]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"g-map/compute\",\"unless\",\"-private-api/addon-factory\",\"component\",\"hash\",\"with\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map.hbs"
    }
  });
});