define("ember-model-validator/mixins/model-validator", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "ember-model-validator/postal-codes-regex", "ember-model-validator/messages/en", "ember-model-validator/messages/ar", "ember-model-validator/messages/fr", "ember-model-validator/messages/es", "ember-model-validator/messages/pt-br"], function (_exports, _slicedToArray2, _createForOfIteratorHelper2, _postalCodesRegex, _en, _ar, _fr, _es, _ptBr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Messages = {
    en: _en.default,
    ar: _ar.default,
    fr: _fr.default,
    es: _es.default,
    'pt-br': _ptBr.default
  };
  var _default = _exports.default = Ember.Mixin.create({
    validationErrors: {},
    isValidNow: true,
    addErrors: true,
    _validationMessages: {},
    _locale: Ember.computed(function () {
      return Ember.getOwner(this).lookup('validator:locale');
    }),
    _initMessage: Ember.on('init', function () {
      var locale = Ember.get(this, '_locale') || 'en';
      Ember.set(this, '_validationMessages', Messages[locale]);
    }),
    clearErrors: function clearErrors() {
      this._internalModel.clearErrorMessages();
    },
    validate: function validate() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var errors = null,
        validations = Ember.get(this, 'validations');

      // Clean all the current errors
      this.clearErrors();
      Ember.set(this, 'validationErrors', {});
      Ember.set(this, 'isValidNow', true);
      errors = Ember.get(this, 'validationErrors');

      // Validate but not set errors
      if (options.hasOwnProperty('addErrors')) {
        Ember.set(this, 'addErrors', options['addErrors']);
      } else {
        Ember.set(this, 'addErrors', true);
      }
      // Call validators defined on each property
      for (var property in validations) {
        for (var validation in validations[property]) {
          if (this._exceptOrOnly(property, validation, options)) {
            var validationName = Ember.String.capitalize(validation);
            // allowBlank option
            if (Ember.get(validations[property], "".concat(validation, ".allowBlank")) && Ember.isEmpty(Ember.get(this, property))) {
              continue;
            }
            // conditional functions
            var conditionalFunction = Ember.get(validations[property], "".concat(validation, ".if"));
            if (conditionalFunction && !conditionalFunction(property, Ember.get(this, property), this)) {
              continue;
            }
            this["_validate".concat(validationName)](property, validations[property]);
          }
        }
      }

      // Check if it's valid or not
      if (!Ember.get(this, 'isValidNow')) {
        // It may be invalid because of its relations
        if (Ember.get(this, 'addErrors') && Object.keys(errors).length !== 0) {
          this.pushErrors(errors);
        }
        return false;
      } else {
        return true;
      }
    },
    pushErrors: function pushErrors(errors) {
      var store = Ember.get(this, 'store');
      var stateToTransition = Ember.get(this, 'isNew') ? 'created.uncommitted' : 'updated.uncommitted';
      this.transitionTo(stateToTransition);
      var recordModel = this.adapterDidInvalidate ? this : this._internalModel;
      store.recordWasInvalid(recordModel, errors);
    },
    /**** Validators ****/_validateCustom: function _validateCustom(property, validation) {
      validation = Ember.isArray(validation.custom) ? validation.custom : [validation.custom];
      for (var i = 0; i < validation.length; i++) {
        var customValidator = this._getCustomValidator(validation[i]);
        if (customValidator) {
          var passedCustomValidation = customValidator(property, Ember.get(this, property), this);
          if (!passedCustomValidation) {
            Ember.set(this, 'isValidNow', false);
            this._addToErrors(property, validation[i], Ember.get(this, '_validationMessages').customValidationMessage);
          }
        }
      }
    },
    _validatePresence: function _validatePresence(property, validation) {
      var propertyValue = Ember.get(this, property);
      // If the property is an async relationship.
      if (this._modelRelations() && !Ember.isBlank(this._modelRelations()[property])) {
        if (this._modelRelations()[property]['isAsync']) {
          propertyValue = Ember.get(this, "".concat(property, ".content"));
        }
      }
      if (Ember.isBlank(propertyValue)) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.presence, Ember.get(this, '_validationMessages').presenceMessage);
      }
    },
    _validateAbsence: function _validateAbsence(property, validation) {
      if (Ember.isPresent(Ember.get(this, property))) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.absence, Ember.get(this, '_validationMessages').absenceMessage);
      }
    },
    _validateAcceptance: function _validateAcceptance(property, validation) {
      var propertyValue = Ember.get(this, property),
        accept = validation.acceptance.accept || [1, '1', true];
      if (!this._includes(Ember.A(accept), propertyValue)) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.acceptance, Ember.get(this, '_validationMessages').acceptanceMessage);
      }
    },
    _validateFormat: function _validateFormat(property, validation) {
      var withRegexp = validation.format.with;
      if (Ember.get(this, property) && String(Ember.get(this, property)).match(withRegexp) === null) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.format, Ember.get(this, '_validationMessages').formatMessage);
      }
    },
    _validateEmail: function _validateEmail(property, validation) {
      if (!Ember.get(this, property) || String(Ember.get(this, property)).match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) === null) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.email, Ember.get(this, '_validationMessages').mailMessage);
      }
    },
    _validateZipCode: function _validateZipCode(property, validation) {
      var DEFAULT_COUNTRY_CODE = 'US';
      var propertyValue = Ember.get(this, property);
      var countryCode = DEFAULT_COUNTRY_CODE;
      if (validation.zipCode.hasOwnProperty('countryCode')) {
        countryCode = validation.zipCode.countryCode;
      }
      if (Ember.isArray(countryCode)) {
        countryCode.forEach(function (code) {
          var postalCodeRegexp = _postalCodesRegex.default[code];
          if (typeof postalCodeRegexp === 'undefined') {
            postalCodeRegexp = _postalCodesRegex.default[DEFAULT_COUNTRY_CODE];
          }
          if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
            Ember.set(this, 'isValidNow', false);
            this._addToErrors(property, validation.zipCode, Ember.get(this, '_validationMessages').zipCodeMessage);
          }
        });
      } else {
        var postalCodeRegexp = _postalCodesRegex.default[countryCode];
        if (typeof postalCodeRegexp === 'undefined') {
          postalCodeRegexp = _postalCodesRegex.default[DEFAULT_COUNTRY_CODE];
        }
        if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
          Ember.set(this, 'isValidNow', false);
          this._addToErrors(property, validation.zipCode, Ember.get(this, '_validationMessages').zipCodeMessage);
        }
      }
    },
    _validateColor: function _validateColor(property, validation) {
      var propertyValue = Ember.get(this, property);
      if (!propertyValue || String(propertyValue).match(/([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i) === null) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.color, Ember.get(this, '_validationMessages').colorMessage);
      }
    },
    _validateURL: function _validateURL(property, validation) {
      var propertyValue = Ember.get(this, property);
      if (!propertyValue || String(propertyValue).match(/^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/) === null) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.URL, Ember.get(this, '_validationMessages').URLMessage);
      }
    },
    _validateSubdomain: function _validateSubdomain(property, validation) {
      var propertyValue = Ember.get(this, property),
        reserved = validation.subdomain.reserved || [];
      if (!propertyValue || String(propertyValue).match(/^[a-z\d]+([-_][a-z\d]+)*$/i) === null || reserved.indexOf(propertyValue) !== -1) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.subdomain, Ember.get(this, '_validationMessages').subdomainMessage);
      }
    },
    _validateDate: function _validateDate(property, validation) {
      var propertyValue = new Date(Ember.get(this, property));
      if (isNaN(propertyValue.getTime())) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.date, Ember.get(this, '_validationMessages').dateMessage);
        return;
      }
      if (validation.date.hasOwnProperty('before') && validation.date.before) {
        if (propertyValue.getTime() >= new Date(validation.date.before).getTime()) {
          Ember.set(this, 'isValidNow', false);
          var context = {
            date: new Date(validation.date.before)
          };
          validation.date.interpolatedValue = validation.date.before;
          this._addToErrors(property, validation.date, this._formatMessage(Ember.get(this, '_validationMessages').dateBeforeMessage, context));
        }
      }
      if (validation.date.hasOwnProperty('after') && validation.date.after) {
        if (propertyValue.getTime() <= new Date(validation.date.after).getTime()) {
          Ember.set(this, 'isValidNow', false);
          var _context = {
            date: new Date(validation.date.after)
          };
          validation.date.interpolatedValue = validation.date.after;
          this._addToErrors(property, validation.date, this._formatMessage(Ember.get(this, '_validationMessages').dateAfterMessage, _context));
        }
      }
    },
    _validateNumericality: function _validateNumericality(property, validation) {
      var propertyValue = Ember.get(this, property);
      if (!this._isNumber(Ember.get(this, property))) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation.numericality, Ember.get(this, '_validationMessages').numericalityMessage);
      }
      if (validation.numericality.hasOwnProperty('onlyInteger') && validation.numericality.onlyInteger) {
        if (!/^[+-]?\d+$/.test(propertyValue)) {
          Ember.set(this, 'isValidNow', false);
          this._addToErrors(property, validation.numericality, Ember.get(this, '_validationMessages').numericalityOnlyIntegerMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('even') && validation.numericality.even) {
        if (propertyValue % 2 !== 0) {
          Ember.set(this, 'isValidNow', false);
          this._addToErrors(property, validation.numericality, Ember.get(this, '_validationMessages').numericalityEvenMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('odd') && validation.numericality.odd) {
        if (propertyValue % 2 === 0) {
          Ember.set(this, 'isValidNow', false);
          this._addToErrors(property, validation.numericality, Ember.get(this, '_validationMessages').numericalityOddMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('greaterThan') && this._isNumber(validation.numericality.greaterThan)) {
        if (propertyValue <= validation.numericality.greaterThan) {
          Ember.set(this, 'isValidNow', false);
          var context = {
            count: validation.numericality.greaterThan
          };
          validation.numericality.interpolatedValue = validation.numericality.greaterThan;
          this._addToErrors(property, validation.numericality, this._formatMessage(Ember.get(this, '_validationMessages').numericalityGreaterThanMessage, context));
        }
      }
      if (validation.numericality.hasOwnProperty('greaterThanOrEqualTo') && this._isNumber(validation.numericality.greaterThanOrEqualTo)) {
        if (propertyValue < validation.numericality.greaterThanOrEqualTo) {
          Ember.set(this, 'isValidNow', false);
          var _context2 = {
            count: validation.numericality.greaterThanOrEqualTo
          };
          validation.numericality.interpolatedValue = validation.numericality.greaterThanOrEqualTo;
          this._addToErrors(property, validation.numericality, this._formatMessage(Ember.get(this, '_validationMessages').numericalityGreaterThanOrEqualToMessage, _context2));
        }
      }
      if (validation.numericality.hasOwnProperty('equalTo') && this._isNumber(validation.numericality.equalTo)) {
        if (propertyValue !== validation.numericality.equalTo) {
          Ember.set(this, 'isValidNow', false);
          var _context3 = {
            count: validation.numericality.equalTo
          };
          validation.numericality.interpolatedValue = validation.numericality.equalTo;
          this._addToErrors(property, validation.numericality, this._formatMessage(Ember.get(this, '_validationMessages').numericalityEqualToMessage, _context3));
        }
      }
      if (validation.numericality.hasOwnProperty('lessThan') && this._isNumber(validation.numericality.lessThan)) {
        if (propertyValue >= validation.numericality.lessThan) {
          Ember.set(this, 'isValidNow', false);
          var _context4 = {
            count: validation.numericality.lessThan
          };
          validation.numericality.interpolatedValue = validation.numericality.lessThan;
          this._addToErrors(property, validation.numericality, this._formatMessage(Ember.get(this, '_validationMessages').numericalityLessThanMessage, _context4));
        }
      }
      if (validation.numericality.hasOwnProperty('lessThanOrEqualTo') && this._isNumber(validation.numericality.lessThanOrEqualTo)) {
        if (propertyValue > validation.numericality.lessThanOrEqualTo) {
          Ember.set(this, 'isValidNow', false);
          var _context5 = {
            count: validation.numericality.lessThanOrEqualTo
          };
          validation.numericality.interpolatedValue = validation.numericality.lessThanOrEqualTo;
          this._addToErrors(property, validation.numericality, this._formatMessage(Ember.get(this, '_validationMessages').numericalityLessThanOrEqualToMessage, _context5));
        }
      }
    },
    _validateExclusion: function _validateExclusion(property, validation) {
      if (validation.exclusion.hasOwnProperty('in')) {
        if (validation.exclusion.in.indexOf(Ember.get(this, property)) !== -1) {
          Ember.set(this, 'isValidNow', false);
          this._addToErrors(property, validation.exclusion, Ember.get(this, '_validationMessages').exclusionMessage);
        }
      }
    },
    _validateInclusion: function _validateInclusion(property, validation) {
      if (validation.inclusion.hasOwnProperty('in')) {
        if (validation.inclusion.in.indexOf(Ember.get(this, property)) === -1) {
          Ember.set(this, 'isValidNow', false);
          this._addToErrors(property, validation.inclusion, Ember.get(this, '_validationMessages').inclusionMessage);
        }
      }
    },
    _validateMatch: function _validateMatch(property, validation) {
      var matching = validation.match.attr || validation.match,
        propertyValue = Ember.get(this, property),
        matchingValue = Ember.get(this, matching);
      if (propertyValue !== matchingValue) {
        Ember.set(this, 'isValidNow', false);
        var matchingUnCamelCase = this._unCamelCase(matching);
        var context = {
          match: matchingUnCamelCase
        };
        if (Ember.typeOf(validation.match) === 'object') {
          validation.match.interpolatedValue = matchingUnCamelCase;
        }
        this._addToErrors(property, validation.match, this._formatMessage(Ember.get(this, '_validationMessages').matchMessage, context));
      }
    },
    // Length Validator
    _validateLength: function _validateLength(property, validation) {
      var propertyValue = Ember.get(this, property),
        stringLength = !propertyValue ? 0 : String(propertyValue).length,
        validationType = Ember.typeOf(validation.length);
      if (validationType === 'number') {
        validation.length = {
          is: validation.length
        };
        this._exactLength(stringLength, property, validation);
      } else if (validationType === 'array') {
        validation.length = {
          minimum: validation.length[0],
          maximum: validation.length[1]
        };
        this._rangeLength(stringLength, property, validation);
      } else if (validationType === 'object') {
        if (validation.length.hasOwnProperty('is')) {
          this._exactLength(stringLength, property, validation);
        } else {
          this._rangeLength(stringLength, property, validation);
        }
      }
    },
    _exactLength: function _exactLength(stringLength, property, validation) {
      if (stringLength !== validation.length.is) {
        Ember.set(this, 'isValidNow', false);
        var context = {
          count: validation.length.is
        };
        validation.length.interpolatedValue = validation.length.is;
        this._addToErrors(property, validation.length, this._formatMessage(Ember.get(this, '_validationMessages').wrongLengthMessage, context));
      }
    },
    _rangeLength: function _rangeLength(stringLength, property, validation) {
      var minimum = -1,
        maximum = Infinity;
      // Maximum and Minimum can be objects
      if (Ember.typeOf(validation.length.minimum) === 'number') {
        minimum = validation.length.minimum;
      } else if (Ember.typeOf(validation.length.minimum) === 'object' && validation.length.minimum.hasOwnProperty('value')) {
        minimum = validation.length.minimum.value;
      }
      if (Ember.typeOf(validation.length.maximum) === 'number') {
        maximum = validation.length.maximum;
      } else if (Ember.typeOf(validation.length.maximum) === 'object' && validation.length.maximum.hasOwnProperty('value')) {
        maximum = validation.length.maximum.value;
      }
      if (stringLength < minimum) {
        Ember.set(this, 'isValidNow', false);
        var context = {
          count: minimum
        };
        if (Ember.typeOf(validation.length.minimum) === 'object') {
          validation.length.minimum.interpolatedValue = minimum;
        }
        this._addToErrors(property, validation.length.minimum, this._formatMessage(Ember.get(this, '_validationMessages').tooShortMessage, context));
      } else if (stringLength > maximum) {
        Ember.set(this, 'isValidNow', false);
        var _context6 = {
          count: maximum
        };
        if (Ember.typeOf(validation.length.maximum) === 'object') {
          validation.length.maximum.interpolatedValue = maximum;
        }
        this._addToErrors(property, validation.length.maximum, this._formatMessage(Ember.get(this, '_validationMessages').tooLongMessage, _context6));
      }
    },
    _validateRelations: function _validateRelations(property, validation) {
      var _this = this;
      if (validation.relations.indexOf('hasMany') !== -1) {
        if (Ember.get(this, "".concat(property, ".content"))) {
          Ember.get(this, "".concat(property, ".content")).forEach(function (objRelation) {
            if (!objRelation.validate()) {
              Ember.set(_this, 'isValidNow', false);
            }
          });
        }
      } else if (validation.relations.indexOf('belongsTo') !== -1) {
        if (Ember.get(this, "".concat(property, ".content")) && !Ember.get(this, "".concat(property, ".content")).validate()) {
          Ember.set(this, 'isValidNow', false);
        }
      }
    },
    _validateMustContainCapital: function _validateMustContainCapital(property, validation) {
      var notContainCapital = String(Ember.get(this, property)).match(/(?=.*[A-Z])/) === null,
        message = validation.mustContainCapital.message || Ember.get(this, '_validationMessages').mustContainCapitalMessage;
      if (validation.mustContainCapital && notContainCapital) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainLower: function _validateMustContainLower(property, validation) {
      var containsLower = String(Ember.get(this, property)).match(/(?=.*[a-z])/) !== null,
        message = validation.mustContainLower.message || Ember.get(this, '_validationMessages').mustContainLowerMessage;
      if (validation.mustContainLower && !containsLower) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainNumber: function _validateMustContainNumber(property, validation) {
      var containsNumber = String(Ember.get(this, property)).match(/(?=.*[0-9])/) !== null,
        message = validation.mustContainNumber.message || Ember.get(this, '_validationMessages').mustContainNumberMessage;
      if (validation.mustContainNumber && !containsNumber) {
        Ember.set(this, 'isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainSpecial: function _validateMustContainSpecial(property, validation) {
      var regexString = validation.mustContainSpecial.acceptableChars || '-+_!@#$%^&*.,?()',
        regex = new RegExp("(?=.*[".concat(regexString, "])")),
        containsSpecial = String(Ember.get(this, property)).match(regex) !== null,
        message = validation.mustContainSpecial.message || Ember.get(this, '_validationMessages').mustContainSpecialMessage;
      if (validation.mustContainSpecial && !containsSpecial) {
        Ember.set(this, 'isValidNow', false);
        var context = {
          characters: regexString
        };
        this._addToErrors(property, validation, this._formatMessage(message, context));
      }
    },
    /**** Helper methods ****/_exceptOrOnly: function _exceptOrOnly(property, validation, options) {
      var validateThis = true;
      if (Ember.isPresent(options.except) && Ember.isArray(options.except)) {
        validateThis = !this._hasCompositeTag(property, validation, options.except);
      }
      if (Ember.isPresent(options.only) && Ember.isArray(options.only)) {
        validateThis = this._hasCompositeTag(property, validation, options.only);
      }
      return validateThis;
    },
    _hasCompositeTag: function _hasCompositeTag(property, validation, tags) {
      var _iterator = (0, _createForOfIteratorHelper2.default)(tags),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var tag = _step.value;
          if (tag === property) return true;
          if (tag.indexOf(':') !== -1) {
            var _tag$split = tag.split(':', 2),
              _tag$split2 = (0, _slicedToArray2.default)(_tag$split, 2),
              field = _tag$split2[0],
              _tag$split2$ = _tag$split2[1],
              rest = _tag$split2$ === void 0 ? '' : _tag$split2$;
            if (field !== property) continue;
            var rules = rest.split(',');
            var _iterator2 = (0, _createForOfIteratorHelper2.default)(rules),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var rule = _step2.value;
                if (rule === validation) return true;
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return false;
    },
    _getCustomValidator: function _getCustomValidator(validation) {
      var customValidator = validation;
      if (Ember.typeOf(validation) === 'object' && validation.hasOwnProperty('validation')) {
        customValidator = validation.validation;
      }
      return this._isFunction(customValidator) ? customValidator : false;
    },
    _getCustomMessage: function _getCustomMessage(validationObj, defaultMessage, property) {
      if (Ember.typeOf(validationObj) === 'object' && validationObj.hasOwnProperty('message')) {
        if (this._isFunction(validationObj.message)) {
          var msg = validationObj.message.call(this, property, Ember.get(this, property), this);
          return this._isString(msg) ? msg : defaultMessage;
        } else {
          var context = {
            value: validationObj.interpolatedValue
          };
          return this._formatMessage(validationObj.message, context);
        }
      } else {
        return defaultMessage;
      }
    },
    _addToErrors: function _addToErrors(property, validation, defaultMessage) {
      var errors = Ember.get(this, 'validationErrors'),
        message = this._getCustomMessage(validation, defaultMessage, property),
        errorAs = Ember.typeOf(validation) === 'object' ? validation.errorAs || property : property;
      if (!Ember.isArray(errors[errorAs])) {
        errors[errorAs] = [];
      }
      if (Ember.get(this, 'addErrors')) {
        errors[errorAs].push([message]);
      }
    },
    // Specific funcs
    _isNumber: function _isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    _unCamelCase: function _unCamelCase(str) {
      return str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return Ember.String.capitalize(str);
      });
    },
    _isFunction: function _isFunction(func) {
      return Ember.isEqual(Ember.typeOf(func), 'function');
    },
    _isString: function _isString(str) {
      return Ember.isEqual(Ember.typeOf(str), 'string');
    },
    _includes: function _includes(enums, value) {
      if (enums.includes) {
        return enums.includes(value);
      } else {
        // Support old ember versions
        return enums.contains(value);
      }
    },
    _modelRelations: function _modelRelations() {
      if (Ember.get(this, '_relationships')) {
        return Ember.get(this, '_relationships');
      } else if (Ember.get(this, '_internalModel._relationships')) {
        return Ember.get(this, '_internalModel._relationships.initializedRelationships');
      } else {
        return Ember.get(this, '_internalModel._recordData._relationships.initializedRelationships');
      }
    },
    _formatMessage: function _formatMessage(message) {
      var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return message.replace(/\{(\w+)\}/, function (s, attr) {
        return context[attr];
      });
    }
  });
});