define("ember-cli-materialize/helpers/bw-compat-icon", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bwCompatIcon = bwCompatIcon;
  _exports.default = void 0;
  _exports.isOldIcon = isOldIcon;
  function isOldIcon(str) {
    return str.split(' ').filter(function (c) {
      return c.indexOf('mdi-') === 0;
    }).length > 0;
  }
  function bwCompatIcon(params, hash) {
    var _params = (0, _slicedToArray2.default)(params, 1),
      iconStr = _params[0];
    var extraClassesString = (hash || {}).extraClasses || null;
    var extraClasses = extraClassesString ? extraClassesString.split(' ') : [];
    if (isOldIcon(iconStr)) {
      return Ember.String.htmlSafe("<i class='".concat(Ember.A([iconStr].concat(extraClasses)).compact().join(' '), "'></i>"));
    } else {
      var classes = iconStr.split(' ');
      var icon = classes.shift();
      var classString = Ember.A(['material-icons'].concat(classes).concat(extraClasses)).compact().join(' ');
      return Ember.String.htmlSafe("<i class='".concat(classString, "'>").concat(icon, "</i>"));
    }
  }
  var _default = _exports.default = Ember.Helper.helper(bwCompatIcon);
});