define("ember-cli-materialize/templates/components/md-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MaQ99SwJ",
    "block": "{\"symbols\":[\"row\",\"column\",\"&default\"],\"statements\":[[9,\"thead\",true],[10],[1,1,0,0,\"\\n  \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[31,66,9,[27,[26,0,\"CallHead\"],[]],[[27,[24,2],[\"headerComponentName\"]]],[[\"column\"],[[27,[24,2],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[2]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[9,\"tbody\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n      \"],[16,3,[[27,[24,1],[]]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[11]],\"hasEval\":false,\"upvars\":[\"component\",\"columnComponents\",\"-track-array\",\"each\",\"content\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-table.hbs"
    }
  });
});