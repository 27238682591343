define("ember-composable-helpers/helpers/compact", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compact = compact;
  _exports.default = void 0;
  function compact(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      value = _ref2[0];
    var array;
    if (Array.isArray(value) || Ember.isArray(value)) {
      array = value;
    } else {
      array = [value];
    }
    return array.filter(function (item) {
      return Ember.isPresent(item);
    });
  }
  var _default = _exports.default = Ember.Helper.helper(compact);
});