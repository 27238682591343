define("ember-keyboard/utils/handle-key-event", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "ember-keyboard/utils/get-mouse-name", "ember-keyboard/utils/listener-name", "ember-keyboard/utils/is-key"], function (_exports, _createForOfIteratorHelper2, _getMouseName, _listenerName2, _isKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getListenerNames = getListenerNames;
  _exports.handleKeyEventWithLaxPriorities = handleKeyEventWithLaxPriorities;
  _exports.handleKeyEventWithPropagation = handleKeyEventWithPropagation;
  _exports.triggerViaLegacyResponderApi = triggerViaLegacyResponderApi;
  function modifierStrings(event) {
    if (event instanceof KeyboardEvent) {
      return ['alt', 'ctrl', 'meta', 'shift'].reduce(function (result, keyName) {
        if (event["".concat(keyName, "Key")]) {
          result.push(keyName);
        }
        return result;
      }, []);
    } else if (event instanceof MouseEvent) {
      var mouseButton = (0, _getMouseName.default)(event.button);
      if (mouseButton) {
        return [mouseButton];
      }
      return [];
    }
  }
  function handleKeyEventWithPropagation(event, _ref) {
    var firstResponders = _ref.firstResponders,
      normalResponders = _ref.normalResponders;
    var isImmediatePropagationStopped = false;
    var isPropagationStopped = false;
    var ekEvent = {
      stopImmediatePropagation: function stopImmediatePropagation() {
        isImmediatePropagationStopped = true;
      },
      stopPropagation: function stopPropagation() {
        isPropagationStopped = true;
      }
    };
    /* eslint-disable no-unused-vars */
    var _iterator = (0, _createForOfIteratorHelper2.default)(firstResponders),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var responder = _step.value;
        triggerResponderListener(responder, event, ekEvent);
        if (isImmediatePropagationStopped) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    if (isPropagationStopped) {
      return;
    }
    isImmediatePropagationStopped = false;
    var previousPriorityLevel = Number.POSITIVE_INFINITY;
    var _iterator2 = (0, _createForOfIteratorHelper2.default)(normalResponders),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _responder = _step2.value;
        var currentPriorityLevel = Number(_responder.keyboardPriority);
        if (isImmediatePropagationStopped && currentPriorityLevel === previousPriorityLevel) {
          continue;
        }
        if (currentPriorityLevel < previousPriorityLevel) {
          if (isPropagationStopped) {
            return;
          }
          isImmediatePropagationStopped = false;
          previousPriorityLevel = currentPriorityLevel;
        }
        triggerResponderListener(_responder, event, ekEvent);
      }
      /* eslint-enable no-unused-vars */
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
  function handleKeyEventWithLaxPriorities(event, sortedResponders) {
    var currentPriorityLevel;
    var noFirstResponders = true;
    var isLax = true;
    sortedResponders.every(function (responder) {
      var keyboardFirstResponder = responder.keyboardFirstResponder;
      var keyboardPriority = responder.keyboardPriority;
      if (keyboardFirstResponder || noFirstResponders && keyboardPriority >= currentPriorityLevel || isLax) {
        if (!responder.keyboardLaxPriority) {
          isLax = false;
        }
        if (keyboardFirstResponder) {
          if (!isLax) {
            noFirstResponders = false;
          }
        } else {
          currentPriorityLevel = keyboardPriority;
        }
        triggerResponderListener(responder, event);
        return true;
      } else {
        return false;
      }
    });
  }
  function triggerResponderListener(responder, event) {
    var ekEvent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    if (responder.handleKeyboardEvent) {
      if (responder.canHandleKeyboardEvent && !responder.canHandleKeyboardEvent(event)) {
        return;
      }
      responder.handleKeyboardEvent(event, ekEvent);
      return;
    }
    if (responder.keyboardHandlers) {
      Object.keys(responder.keyboardHandlers).forEach(function (responderListenerName) {
        if ((0, _isKey.default)(responderListenerName, event)) {
          if (ekEvent) {
            responder.keyboardHandlers[responderListenerName](event, ekEvent);
          } else {
            responder.keyboardHandlers[responderListenerName](event);
          }
        }
      });
      return;
    }
    if (responder.trigger) {
      (true && !(false) && Ember.deprecate('ember-keyboard registered responders handling events via `trigger(listenerName, event)` is deprecated. A responder should have either `keyboardHandlers` (a property returning a dictionary of listenerNames to handler functions), or `handleKeyboardEvent(event)`.', false, {
        id: 'ember-keyboard.responder-trigger',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#responder-trigger'
      }));
      triggerViaLegacyResponderApi(responder, event, ekEvent);
      return;
    }
    throw new Error('A responder registered with the ember-keyboard service must implement either `keyboardHandlers` (property returning a dictionary of listenerNames to handler functions), or `handleKeyboardEvent(event)`)');
  }
  function getListenerNames(event) {
    var result = [];
    if (event instanceof KeyboardEvent) {
      if (event.key) {
        result.push((0, _listenerName2.default)(event.type, modifierStrings(event).concat([event.key]).join('+')));
      }
      if (event.code && event.key !== event.code) {
        result.push((0, _listenerName2.default)(event.type, modifierStrings(event).concat([event.code]).join('+')));
      }
    } else if (event instanceof MouseEvent) {
      var modifiers = modifierStrings(event);
      if (modifiers.length) {
        result.push((0, _listenerName2.default)(event.type, modifierStrings(event).join('+')));
      }
    }
    result.push((0, _listenerName2.default)(event.type));
    return result;
  }
  function triggerViaLegacyResponderApi(responder, event, ekEvent) {
    var _iterator3 = (0, _createForOfIteratorHelper2.default)(getListenerNames(event)),
      _step3;
    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var _listenerName = _step3.value;
        if (responder.has && !responder.has(_listenerName)) {
          continue;
        }
        if (ekEvent) {
          responder.trigger(_listenerName, event, ekEvent);
        } else {
          responder.trigger(_listenerName, event);
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  }
});