define("@html-next/vertical-collection/components/vertical-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6ep+tLFe",
    "block": "{\"symbols\":[\"virtualComponent\",\"&else\",\"&default\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,66,7,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"upperBound\"]]],null]],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,1],[\"isOccludedContent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,[31,160,7,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"element\"]]],null]]],\"parameters\":[]},{\"statements\":[[16,3,[[27,[24,1],[\"content\"]],[27,[24,1],[\"index\"]]]]],\"parameters\":[]}]]],[1,0,0,0,[31,291,7,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"lowerBound\"]]],null]]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,2,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unbound\",\"if\",\"virtualComponents\",\"-track-array\",\"each\",\"shouldYieldToInverse\"]}",
    "meta": {
      "moduleName": "@html-next/vertical-collection/components/vertical-collection/template.hbs"
    }
  });
});