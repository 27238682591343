define("ember-cli-materialize/templates/components/md-card-collapsible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "U+CfqC9H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-card-collapsible.hbs"
    }
  });
});