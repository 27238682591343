define('ember-cli-guid/utils/guid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var GUID = /^[0-9a-f]{8}-?([0-9a-f]{4}-?){3}[0-9a-f]{12}$/i;

  exports.default = Ember.Object.create({
    create: function create() {
      var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

      guid = guid.replace(/[xy]/g, function (c) {
        var rnd = Math.random() * 16 | 0;
        var v = c === 'x' ? rnd : rnd & 0x3 | 0x8;
        return v.toString(16);
      });

      return guid;
    },
    compact: function compact(guid) {
      if (!new RegExp(GUID).test(guid)) {
        return;
      }

      var g = guid.replace(/-/g, '');
      var rguid = g.substring(6, 8) + g.substring(4, 6) + g.substring(2, 4) + g.substring(0, 2) + g.substring(10, 12) + g.substring(8, 10) + g.substring(14, 16) + g.substring(12, 14) + g.substring(16);

      var hex = rguid.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ');

      var bytes = String.fromCharCode.apply(String, _toConsumableArray(hex));
      var base64 = btoa(bytes);

      return base64.replace(/=/g, '').replace(/\//g, '_').replace(/\+/g, '-');
    },
    expand: function expand(cguid) {
      if (Ember.isNone(cguid) || cguid.length !== 22) {
        return;
      }

      var base64 = cguid.replace(/_/g, '/').replace(/-/g, '+') + '==';
      var bytes = atob(base64);
      var g = [];

      for (var i = 0; i < bytes.length; i++) {
        var item = bytes.charCodeAt(i);
        g.push(('00' + item.toString(16).toLowerCase()).substr(-2, 2));
      }

      var guid = Ember.A(g.slice(0, 4).reverse().concat(g.slice(4, 6).reverse()).concat(g.slice(6, 8).reverse()).concat(g.slice(8)));

      guid.insertAt(4, '-').insertAt(7, '-').insertAt(10, '-').insertAt(13, '-');

      return guid.join('');
    }
  });
});