define("ember-cli-materialize/components/selectable-item", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composability/mixins/child-component-support", "ember-cli-materialize/components/selectable-item-group"], function (_exports, _slicedToArray2, _childComponentSupport, _selectableItemGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_childComponentSupport.default, {
    // eslint-disable-next-line
    _parentComponentTypes: [_selectableItemGroup.default],
    checked: null,
    disabled: false,
    classNames: ['materialize-selectable-item'],
    _checked: Ember.computed('checked', 'group.selection', 'group.selection.[]', {
      get: function get() {
        var group = this.get('group');
        if (!group) {
          return this.get('checked');
        } else {
          return group.isValueSelected(this.get('value'));
        }
      },
      set: function set(key, val) {
        var group = this.get('group');
        if (!group) {
          this.set('checked', val);
        } else {
          group.setValueSelection(this.get('value'), val);
        }
        this.sendAction('action', {
          checked: !!val
        });
        return !!val;
      }
    }),
    isSelected: Ember.computed.alias('_checked'),
    _setupLabel: function _setupLabel() {
      var _this$$$toArray = this.$('.materialize-selectable-item-input, .materialize-selectable-item-input-container input').toArray(),
        _this$$$toArray2 = (0, _slicedToArray2.default)(_this$$$toArray, 1),
        $input = _this$$$toArray2[0];
      var inputId = $input ? $input.id : null;
      this.$('.materialize-selectable-item-label').attr('for', inputId);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._setupLabel();
    },
    group: Ember.computed(function () {
      return this.nearestWithProperty('__materializeSelectableItemGroup');
    })
  });
});