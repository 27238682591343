define("ember-composable-helpers/helpers/take", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.take = take;
  function take(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      takeAmount = _ref2[0],
      array = _ref2[1];
    return (0, _asArray.default)(array).slice(0, takeAmount);
  }
  var _default = _exports.default = Ember.Helper.helper(take);
});