define("ember-cli-string-helpers/utils/uppercase", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = uppercase;
  function uppercase() {
    var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    if (typeof string !== 'string') {
      throw new TypeError("Expected a string, got a ".concat((0, _typeof2.default)(string)));
    }
    return string.toUpperCase();
  }
});