define("ember-data-storefront/-private/utils/get-key", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shoeboxize = _exports.serializeObject = _exports.queryCacheKey = _exports.cacheKey = void 0;
  var _serializeParams = function _serializeParams() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var prefix = arguments.length > 1 ? arguments[1] : undefined;
    var query = Object.keys(params).sort().map(function (key) {
      var value = params[key];
      if (Array.isArray(params)) {
        key = "".concat(prefix, "[]");
      } else if (params === Object(params)) {
        key = prefix ? "".concat(prefix, "[").concat(key, "]") : key;
      }
      if ((0, _typeof2.default)(value) === 'object' && value !== null) {
        return _serializeParams(value, key);
      } else {
        return "".concat(key, "=").concat(encodeURIComponent(value));
      }
    });
    return [].concat.apply([], query).join('&');
  };
  var serializeObject = _exports.serializeObject = function serializeObject(params) {
    return _serializeParams(params);
  };
  var queryCacheKey = _exports.queryCacheKey = function queryCacheKey(query) {
    return cacheKey([query.type, query.id, query.params]);
  };
  var cacheKey = _exports.cacheKey = function cacheKey(args) {
    return args.map(function (part) {
      return (0, _typeof2.default)(part) === "object" ? serializeObject(part) : part;
    }).filter(function (part) {
      return !!part;
    }).join('::');
  };
  var shoeboxize = _exports.shoeboxize = function shoeboxize(key) {
    return key.replace(/&/g, '--'); // IDGAF
  };
});