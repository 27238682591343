define("ember-cli-materialize/templates/components/md-copyright", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9p4LxeZl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"container\",null],[10],[1,1,0,0,\"© \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\" \"],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\" \"],[16,1,null],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"date\",\"text\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-copyright.hbs"
    }
  });
});