define("ember-cli-materialize/templates/components/md-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OEyulTwL",
    "block": "{\"symbols\":[],\"statements\":[[9,\"span\",true],[12,\"class\",\"switch-label materialize-selectable-item-label\",null],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\\n\"],[9,\"label\",true],[13,\"class\",[32,[[27,[26,1,\"AppendSingleId\"],[]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"offlabel\",null],[10],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,159,5,[27,[26,5,\"CallHead\"],[]],null,[[\"type\",\"disabled\",\"checked\"],[\"checkbox\",[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"lever\",null],[10],[11],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"onlabel\",null],[10],[1,0,0,0,[27,[26,6,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"name\",\"_labelClass\",\"offLabel\",\"isSelected\",\"disabled\",\"input\",\"onLabel\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-switch.hbs"
    }
  });
});