define("ember-cli-mirage/deprecate-reexports", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "miragejs", "ember-cli-mirage/index", "@embroider/macros/runtime"], function (_exports, _slicedToArray2, mirage, ecMirageExports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initDeprecatedReExports = initDeprecatedReExports;
  var nonDeprecatedImports = ['default'];

  /**
   @function initDeprecatedReExports
   @hide
   */
  function initDeprecatedReExports() {
    Object.entries(mirage).forEach(function (_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        name = _ref2[0],
        value = _ref2[1];
      if (!nonDeprecatedImports.includes(name)) {
        // eslint-disable-next-line no-import-assign
        Object.defineProperty(ecMirageExports, name, {
          get: function get() {
            if ((0, _runtime.isTesting)() && true) {
              var _importSync = require("@ember/test-helpers"),
                waitUntil = _importSync.waitUntil,
                getContext = _importSync.getContext;
              window.QUnit.begin(function () {
                // Make sure deprecation message does not get "swallowed"
                // when tests run due to
                // https://github.com/emberjs/ember-test-helpers/blob/master/addon-test-support/%40ember/test-helpers/setup-context.ts#L41
                waitUntil(function () {
                  return getContext() !== undefined;
                }).then(function () {
                  return _deprecate(name);
                });
              });
            } else {
              _deprecate(name);
            }
            return value;
          },
          enumerable: true
        });
      }
    });
  }
  function _deprecate(name) {
    var message = "Importing '".concat(name, "' from 'ember-cli-mirage' is deprecated.") + " Install the `miragejs` package and use " + "`import { ".concat(name, " } from 'miragejs';` instead.");
    (true && !(false) && Ember.deprecate(message, false, {
      id: 'ember-cli-mirage.miragejs.import',
      until: '3.0.0',
      for: 'ember-cli-mirage',
      since: {
        enabled: '2.3.0'
      }
    }));
  }
});