define("ember-light-table/templates/components/light-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TNEiSASo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,7,\"CallHead\"],[]],null,[[\"head\",\"body\",\"foot\"],[[31,22,9,[27,[26,6,\"CallHead\"],[]],[\"lt-head\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]]],[31,206,9,[27,[26,6,\"CallHead\"],[]],[\"lt-body\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]]],[31,390,9,[27,[26,6,\"CallHead\"],[]],[\"lt-foot\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]]]]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sharedOptions\",\"tableClassNames\",\"extra\",\"tableActions\",\"table\",\"elementId\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/light-table.hbs"
    }
  });
});