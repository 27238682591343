define("ember-modal-dialog/templates/components/tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "e+pCcynq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,6,\"BlockHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],null,[[\"to\"],[[27,[26,3,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[13,\"class\",[27,[26,0,\"AppendSingleId\"],[]],null],[13,\"onclick\",[31,130,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[27,[26,1,\"Expression\"],[]]],null],null],[12,\"tabindex\",\"-1\",null],[12,\"data-emd-overlay\",\"\",null],[10],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[5,[27,[26,16,\"BlockHead\"],[]],null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\"],[[27,[26,15,\"Expression\"],[]],[27,[26,14,\"Expression\"],[]],[27,[26,13,\"Expression\"],[]],[27,[26,12,\"Expression\"],[]],[27,[26,11,\"Expression\"],[]],[27,[26,10,\"Expression\"],[]],[27,[26,9,\"Expression\"],[]],[27,[26,8,\"Expression\"],[]],[27,[26,7,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"overlayClassNamesString\",\"onClickOverlay\",\"action\",\"destinationElementId\",\"ember-wormhole\",\"hasOverlay\",\"if\",\"constraints\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"targetModifier\",\"targetAttachment\",\"attachment\",\"tetherTarget\",\"containerClassNamesString\",\"ember-tether\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs"
    }
  });
});