define("ember-cli-materialize/components/md-card-reveal", ["exports", "ember-cli-materialize/templates/components/md-card-reveal"], function (_exports, _mdCardReveal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _mdCardReveal.default,
    tagName: 'div',
    classNames: ['card-reveal'],
    classNameBindings: ['class'],
    title: Ember.computed('parentView.title', function () {
      (true && !(true) && Ember.deprecate('Using md-card-reveal without passing it a "title" property (relying on parentView.title) is deprecated.', true, {
        id: 'ember-cli-materialize.deprecate-parentView',
        until: '1.0.0'
      }));
      return this.get('parentView.title');
    }),
    activator: Ember.computed('parentView.activator', function () {
      (true && !(true) && Ember.deprecate('Using md-card-reveal without passing it an "activator" property (relying on parentView.activator) is deprecated.', true, {
        id: 'ember-cli-materialize.deprecate-parentView',
        until: '1.0.0'
      }));
      return this.get('parentView.activator');
    })
  });
});