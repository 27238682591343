define("ember-cli-materialize/templates/components/md-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "W9ShsCgo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,0,0,0,[31,2,5,[27,[26,2,\"CallHead\"],[]],null,[[\"type\",\"class\",\"checked\",\"disabled\"],[\"checkbox\",\"materialize-selectable-item-input\",[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"],[9,\"label\",true],[12,\"class\",\"materialize-selectable-item-label\",null],[10],[1,0,0,0,[27,[26,3,\"AppendSingleId\"],[]]],[16,1,null],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"isSelected\",\"input\",\"name\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-checkbox.hbs"
    }
  });
});