define("ember-cli-materialize/components/md-card", ["exports", "ember-cli-materialize/templates/components/md-card"], function (_exports, _mdCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _mdCard.default,
    classNames: ['card'],
    classNameBindings: ['class']
  });
});