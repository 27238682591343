define("ember-google-maps/components/g-map/info-window", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/info-window", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers"], function (_exports, _objectSpread2, _toConsumableArray2, _mapComponent, _infoWindow, _optionsAndEvents, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.InfoWindowAPI = InfoWindowAPI;
  _exports.default = void 0;
  function InfoWindowAPI(source) {
    var mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      actions: {
        open: function open() {
          return source.open();
        },
        close: function close() {
          return source.close();
        }
      }
    });
  }

  /**
   * A wrapper for the google.maps.InfoWindow class.
   *
   * @class InfoWindow
   * @namespace GMap
   * @module ember-google-maps/components/g-map/info-window
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _infoWindow.default,
    _type: 'infoWindow',
    isOpen: false,
    _cachedIsOpen: false,
    position: Ember.computed('lat', 'lng', _helpers.position),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat((0, _toConsumableArray2.default)(_optionsAndEvents.ignoredOptions), ['isOpen', 'target', 'content'])),
    _createOptions: function _createOptions(options) {
      var newOptions = {
        content: undefined
      };
      if (!Ember.get(this, 'target')) {
        newOptions.position = Ember.get(this, 'position');
      }
      if (Ember.get(this, 'isOpen')) {
        newOptions.content = this._getContent();
      }
      return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, options), newOptions);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.publicAPI = InfoWindowAPI(this);
    },
    _addComponent: function _addComponent(options) {
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', new google.maps.InfoWindow(options)));
    },
    _didAddComponent: function _didAddComponent() {
      this._openOrClose();
      this._super.apply(this, arguments);
    },
    _updateComponent: function _updateComponent(mapComponent, options) {
      mapComponent.setOptions(options);
      this._openOrClose();
    },
    _openOrClose: function _openOrClose() {
      var isOpen = Ember.get(this, 'isOpen');
      var isOpenChanged = this._cachedIsOpen !== isOpen;
      if (isOpenChanged && isOpen) {
        this.open();
      } else if (isOpenChanged && !isOpen) {
        this.close();
      }
      Ember.set(this, '_cachedIsOpen', isOpen);
    },
    _getContent: function _getContent() {
      if (this.content) {
        return this.content;
      }
      var content = document.createElement('div');
      Ember.set(this, '_targetPane', content);
      Ember.set(this, 'content', content);
      return content;
    },
    open: function open() {
      var _this = this;
      if (this.mapComponent) {
        google.maps.event.addListenerOnce(this.mapComponent, 'closeclick', function () {
          Ember.set(_this, 'isOpen', false);
        });
        this.mapComponent.open(Ember.get(this, 'map'), Ember.get(this, 'target'));
      }
    },
    close: function close() {
      if (this.mapComponent) {
        this.mapComponent.close();
      }
    }
  });
});