define("ember-cli-materialize/templates/components/md-card-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZL96CrtO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"span\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]]]],null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"bodyClass\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-card-panel.hbs"
    }
  });
});