define("ember-google-maps/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedPromise = computedPromise;
  _exports.position = position;
  _exports.promisify = promisify;
  function position() {
    var _Ember$getProperties = Ember.getProperties(this, 'lat', 'lng'),
      lat = _Ember$getProperties.lat,
      lng = _Ember$getProperties.lng;
    return lat && lng ? new google.maps.LatLng(lat, lng) : undefined;
  }
  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  function computedPromise() {
    var _Ember;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var func = args.pop();
    return (_Ember = Ember).computed.apply(_Ember, args.concat([function () {
      return ObjectPromiseProxy.create({
        promise: func.apply(this)
      });
    }]));
  }
  function promisify(maybePromise) {
    return maybePromise instanceof Ember.RSVP.Promise ? maybePromise : Ember.RSVP.resolve(maybePromise);
  }
});