define("ember-cli-materialize/templates/components/md-collapsible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VG7bLRgz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[\"collapsible-header \",[31,33,2,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]],\"active\"],null]]],null],[13,\"onClick\",[31,65,6,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[]],\"headerClicked\"],null],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,112,14,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,5,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"collapsible-body\",null],[10],[16,1,null],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"bw-compat-icon\",\"active\",\"if\",\"action\",\"title\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-collapsible.hbs"
    }
  });
});