define("ember-cli-materialize/components/md-table", ["exports", "ember-composability/mixins/parent-component-support", "ember-cli-materialize/templates/components/md-table"], function (_exports, _parentComponentSupport, _mdTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_parentComponentSupport.default, {
    tagName: 'table',
    layout: _mdTable.default,
    columns: null,
    composableChildrenDebounceTime: 1,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('columns', []);
    },
    columnComponents: Ember.computed('composableChildren', function () {
      return Ember.A(this.get('composableChildren'));
    }).readOnly(),
    registerChildComponent: function registerChildComponent(childComponent) {
      this.get('_childComponents').add(childComponent, childComponent.get('key'));
      this._notifyComposableChildrenChanged();
    },
    unregisterChildComponent: function unregisterChildComponent(childComponent) {
      this.get('_childComponents').delete(childComponent, childComponent.get('key'));
      this._notifyComposableChildrenChanged();
    }
  });
});