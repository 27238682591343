define("ember-in-element-polyfill/helpers/-clear-element", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function clearElement(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      element = _ref2[0];
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
    return element;
  });
});