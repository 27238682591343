define("@kockpit/ember-gantt/components/gantt-milestone", ["exports", "@kockpit/ember-gantt/templates/components/gantt-milestone"], function (_exports, _ganttMilestone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Milestones shown in line. Use this as sub-component of the line component.
   ### Usage
   Use as a block level component to wrap tooltip content or inline and set `title` for tooltip content.
   ```handlebars
    {{#gantt-chart dayWidth=10 as |chart|}}
  
      {{#each projects as |p|}}
  
        {{#chart.line dateStart=p.dateStart dateEnd=p.dateEnd as |line|}}
  
          {{! milestone as inline }}
          {{line.milestone date=milestone.date title="Go Live"}}
  
  
          {{#each p.milestones as |milestone|}}
  
            {{! milestones as block (title attribute is ignored) }}
            {{#line.milestone date=milestone.date }}
              <span>{{format-date milestone.date}}:</span>
              {{milestone.title}}
            {{/line.milestone}}
  
          {{/each}}
  
        {{/chart.line}}
      {{/each}}
  
    {{/gantt-chart}}
   ```
  
   @class GanttMilestone
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _ganttMilestone.default,
    classNames: ['gantt-line-milestone'],
    attributeBindings: ['style'],
    line: null,
    chart: null,
    /**
     * date of milestone
     *
     * @property date
     * @argument date
     * @type Date
     * @default null
     * @public
     */
    date: null,
    /**
     * title of milestone. You may also use this component as block element to use more sophisticated markup.
     *
     * @property title
     * @argument title
     * @type string
     * @default null
     * @public
     */
    title: '',
    leftOffset: Ember.computed('date', 'chart.{viewStartDate,dayWidth}', function () {
      return Ember.get(this, 'chart').dateToOffset(Ember.get(this, 'date'));
    }),
    // styling for left/width
    style: Ember.computed('leftOffset', function () {
      var style = "left:".concat(Ember.get(this, 'leftOffset'), "px;");
      return Ember.String.htmlSafe(style);
    }),
    //
    formattedDate: Ember.computed('date', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'date').toLocaleDateString());
    })
  });
});