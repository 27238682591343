define("@kockpit/ember-gantt/templates/components/gantt-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "k9HLw7Gw",
    "block": "{\"symbols\":[\"timeline\",\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"gantt-chart-inner\",null],[10],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],null,[[\"chart\"],[[27,[24,0],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[5,[27,[26,0,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[24,1],[\"header\"]],\"expected `timeline.header` to be a contextual component but found a string. Did you mean `(component timeline.header)`? ('@kockpit/ember-gantt/templates/components/gantt-chart.hbs' @ L5:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[16,2,[[31,149,4,[27,[26,1,\"CallHead\"],[]],null,[[\"header\"],[[31,162,9,[27,[26,0,\"CallHead\"],[]],[\"gantt-generic-content\"],null]]]]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"],[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"gantt-titles-background\",null],[10],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"gantt-lines\",null],[10],[1,1,0,0,\"\\n    \"],[16,2,[[31,372,4,[27,[26,1,\"CallHead\"],[]],null,[[\"line\"],[[31,383,9,[27,[26,0,\"CallHead\"],[]],[\"gantt-line\"],[[\"chart\"],[[27,[24,0],[]]]]]]]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"-assert-implicit-component-helper-argument\",\"gantt-timeline\"]}",
    "meta": {
      "moduleName": "@kockpit/ember-gantt/templates/components/gantt-chart.hbs"
    }
  });
});