define("in-repo-pin-addon/templates/components/g-map-addons/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SnuffzY2",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"marker\"]],\"expected `gMap.marker` to be a contextual component but found a string. Did you mean `(component gMap.marker)`? ('in-repo-pin-addon/templates/components/g-map-addons/pin.hbs' @ L1:C2) \"],null]],[[\"lat\",\"lng\"],[\"51.507568\",\"-0.127762\"]]]]],\"hasEval\":false,\"upvars\":[\"gMap\",\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "meta": {
      "moduleName": "in-repo-pin-addon/templates/components/g-map-addons/pin.hbs"
    }
  });
});