define("ember-google-maps/templates/components/g-map/marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OgqGdbnN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],[[27,[24,0],[\"mapComponent\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,[[31,37,4,[27,[26,4,\"CallHead\"],[]],null,[[\"infoWindow\"],[[31,60,9,[27,[26,3,\"CallHead\"],[]],[\"g-map/info-window\"],[[\"map\",\"_internalAPI\",\"gMap\",\"target\"],[[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]],[27,[24,0],[\"mapComponent\"]]]]]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"gMap\",\"_internalAPI\",\"map\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/marker.hbs"
    }
  });
});