define("liquid-fire/templates/components/illiquid-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ECAlib+G",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[27,[26,0,\"Expression\"],[]]]]],\"hasEval\":false,\"upvars\":[\"_fixedModel\"]}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/illiquid-model.hbs"
    }
  });
});