define("liquid-fire/helpers/lf-lock-model", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "liquid-fire/ember-internals"], function (_exports, _slicedToArray2, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lfLockModel = lfLockModel;
  function lfLockModel(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      routeInfo = _ref2[0],
      outletName = _ref2[1];
    // ensures that the name is locked, see implementation of `routeModel`
    (0, _emberInternals.routeModel)((0, _emberInternals.childRoute)(routeInfo, outletName));
    return routeInfo;
  }
  var _default = _exports.default = Ember.Helper.helper(lfLockModel);
});