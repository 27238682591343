define("ember-cli-materialize/templates/components/md-btn-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Bj8qq1G4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,17,14,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],[[\"extraClasses\"],[[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,0,0,0,[27,[26,4,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[9,\"ul\",true],[13,\"id\",[32,[[27,[26,5,\"AppendSingleId\"],[]]]],null],[12,\"class\",\"dropdown-content\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"iconPosition\",\"icon\",\"bw-compat-icon\",\"if\",\"text\",\"_dropdownContentId\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-btn-dropdown.hbs"
    }
  });
});