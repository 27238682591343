define("ember-metrics/metrics-adapters/google-analytics", ["exports", "ember-metrics/utils/object-transforms", "ember-metrics/utils/remove-from-dom", "ember-metrics/metrics-adapters/base"], function (_exports, _objectTransforms, _removeFromDom, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var compact = _objectTransforms.default.compact;
  var _default = _exports.default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'GoogleAnalytics';
    },
    init: function init() {
      var config = Ember.assign({}, this.config);
      var id = config.id,
        sendHitTask = config.sendHitTask,
        trace = config.trace,
        require = config.require,
        debug = config.debug;
      (true && !(id) && Ember.assert("[ember-metrics] You must pass a valid `id` to the ".concat(this.toString(), " adapter"), id));
      delete config.id;
      delete config.require;
      delete config.debug;
      delete config.sendHitTask;
      delete config.trace;
      var hasOptions = Ember.isPresent(Object.keys(config));

      /* eslint-disable */
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments);
        }, i[r].l = 1 * new Date();
        a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', "https://www.google-analytics.com/analytics".concat(debug ? '_debug' : '', ".js"), 'ga');
      /* eslint-enable */

      if (trace === true) {
        window.ga_debug = {
          trace: true
        };
      }
      window.ga('create', id, hasOptions ? config : 'auto');
      if (require) {
        require.forEach(function (plugin) {
          window.ga('require', plugin);
        });
      }
      if (sendHitTask === false) {
        window.ga('set', 'sendHitTask', null);
      }
    },
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = compact(options);
      var distinctId = compactedOptions.distinctId;
      window.ga('set', 'userId', distinctId);
    },
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = compact(options);
      var sendEvent = {
        hitType: 'event'
      };
      var eventKeys = ['category', 'action', 'label', 'value'];
      var gaEvent = {};
      if (compactedOptions.nonInteraction) {
        gaEvent.nonInteraction = compactedOptions.nonInteraction;
        delete compactedOptions.nonInteraction;
      }
      for (var key in compactedOptions) {
        if (eventKeys.includes(key)) {
          var capitalizedKey = Ember.String.capitalize(key);
          gaEvent["event".concat(capitalizedKey)] = compactedOptions[key];
        } else {
          gaEvent[key] = compactedOptions[key];
        }
      }
      var event = Ember.assign(sendEvent, gaEvent);
      window.ga('send', event);
      return event;
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = compact(options);
      var sendEvent = {
        hitType: 'pageview'
      };
      var event = Ember.assign(sendEvent, compactedOptions);
      for (var key in compactedOptions) {
        // eslint-disable-next-line
        if (compactedOptions.hasOwnProperty(key)) {
          window.ga('set', key, compactedOptions[key]);
        }
      }
      window.ga('send', sendEvent);
      return event;
    },
    willDestroy: function willDestroy() {
      (0, _removeFromDom.default)('script[src*="google-analytics"]');
      delete window.ga;
    }
  });
});