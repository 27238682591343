define("@postedin/ember-ckeditor/components/ckeditor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zsOKb0Yk",
    "block": "{\"symbols\":[\"@editor\",\"&attrs\",\"@disabled\"],\"statements\":[[9,\"div\",false],[14,\"class\",[32,[[31,14,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"document\"]],\"ckeditor-document\"],null]]],null],[15,2],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",false],[14,\"class\",[32,[[31,92,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"document\"]],\"ckeditor-document-toolbar\"],null]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[\"setupToolbar\"]]],null],[10],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[13,\"class\",[32,[[31,206,2,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"document\"]],\"ckeditor-document-editable\"],null]]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",false],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[31,288,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"createEditor\"]]],null]],null],[3,0,0,[27,[26,3,\"ModifierHead\"],[]],[[27,[24,0],[\"watch\"]],[27,[24,3],[]]],null],[3,0,0,[27,[26,4,\"ModifierHead\"],[]],[[31,376,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"cleanUp\"]]],null]],null],[10],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"fn\",\"did-update\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "@postedin/ember-ckeditor/components/ckeditor/template.hbs"
    }
  });
});