define("@ember-decorators/utils/-private/class-field-descriptor", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _typeof2, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDescriptor = isDescriptor;
  _exports.isFieldDescriptor = isFieldDescriptor;
  function isClassDescriptor(possibleDesc) {
    var _possibleDesc = (0, _slicedToArray2.default)(possibleDesc, 1),
      target = _possibleDesc[0];
    return possibleDesc.length === 1 && typeof target === 'function' && 'prototype' in target && !target.__isComputedDecorator;
  }
  function isFieldDescriptor(possibleDesc) {
    var _possibleDesc2 = (0, _slicedToArray2.default)(possibleDesc, 3),
      target = _possibleDesc2[0],
      key = _possibleDesc2[1],
      desc = _possibleDesc2[2];
    return possibleDesc.length === 3 && (0, _typeof2.default)(target) === 'object' && target !== null && typeof key === 'string' && ((0, _typeof2.default)(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility
    ;
  }
  function isDescriptor(possibleDesc) {
    return isFieldDescriptor(possibleDesc) || isClassDescriptor(possibleDesc);
  }
});