define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/NcUpOd7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"li\",true],[12,\"class\",\"ember-power-select-group\",null],[13,\"aria-disabled\",[31,53,41,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null],null],[12,\"role\",\"option\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"ember-power-select-group-name\",null],[10],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"disabled\",\"ember-power-select-true-string-if-present\",\"groupName\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
    }
  });
});