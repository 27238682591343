define("@kockpit/ember-gantt/templates/components/gantt-line-inline-childs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iZtEW45S",
    "block": "{\"symbols\":[\"period\"],\"statements\":[[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[13,\"style\",[27,[24,1],[\"style\"]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"periods\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@kockpit/ember-gantt/templates/components/gantt-line-inline-childs.hbs"
    }
  });
});