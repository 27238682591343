define("ember-cli-materialize/templates/components/md-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aMpz4Akr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"nav-wrapper\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"container\",null],[10],[1,1,0,0,\"\\n    \"],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"class\",\"route\"],[\"brand-logo\",[27,[26,1,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[9,\"ul\",true],[12,\"class\",\"right hide-on-med-and-down\",null],[10],[1,1,0,0,\"\\n      \"],[16,1,null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\\n  \"],[9,\"a\",true],[12,\"class\",\"button-collapse\",null],[13,\"data-activates\",[32,[[27,[26,3,\"AppendSingleId\"],[]]]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"i\",true],[12,\"class\",\"material-icons\",null],[10],[1,1,0,0,\"menu\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"ul\",true],[13,\"id\",[32,[[27,[26,3,\"AppendSingleId\"],[]]]],null],[12,\"class\",\"side-nav\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"name\",\"homeRoute\",\"link-to\",\"_sideNavId\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-navbar.hbs"
    }
  });
});