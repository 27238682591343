define("liquid-fire/helpers/lf-or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lfOr = lfOr;
  function lfOr(params /*, hash*/) {
    return params.reduce(function (a, b) {
      return a || b;
    }, false);
  }
  var _default = _exports.default = Ember.Helper.helper(lfOr);
});