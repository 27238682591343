define("ember-concurrency/-helpers", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskHelperClosure = taskHelperClosure;
  function taskHelperClosure(helperName, taskMethod, _args, hash) {
    var task = _args[0];
    var outerArgs = _args.slice(1);
    return Ember.run.bind(null, function () {
      if (!task || typeof task[taskMethod] !== 'function') {
        (true && !(false) && Ember.assert("The first argument passed to the `".concat(helperName, "` helper should be a Task object (without quotes); you passed ").concat(task), false));
        return;
      }
      for (var _len = arguments.length, innerArgs = new Array(_len), _key = 0; _key < _len; _key++) {
        innerArgs[_key] = arguments[_key];
      }
      if (hash && hash.value) {
        var event = innerArgs.pop();
        innerArgs.push(Ember.get(event, hash.value));
      }
      return task[taskMethod].apply(task, (0, _toConsumableArray2.default)(outerArgs).concat(innerArgs));
    });
  }
});