define("ember-cli-materialize/templates/components/md-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UVG8Poxm",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[9,\"li\",true],[13,\"class\",[32,[[27,[26,1,\"AppendSingleId\"],[]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"a\",false],[23,\"class\",\"decrement\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"oneBack\"],null],[10],[1,1,0,0,\"\\n    \"],[9,\"i\",true],[12,\"class\",\"material-icons\",null],[10],[1,1,0,0,\"chevron_left\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"li\",true],[13,\"class\",[32,[[27,[24,1],[\"cssClass\"]]]],null],[10],[1,1,0,0,\"\\n    \"],[9,\"a\",false],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"gotoPage\",[27,[24,1],[\"val\"]]],null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[24,1],[\"val\"]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"\\n\"],[9,\"li\",true],[13,\"class\",[32,[[27,[26,5,\"AppendSingleId\"],[]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"a\",false],[23,\"class\",\"increment\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"oneFwd\"],null],[10],[1,1,0,0,\"\\n    \"],[9,\"i\",true],[12,\"class\",\"material-icons\",null],[10],[1,1,0,0,\"chevron_right\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"decrementClass\",\"_pages\",\"-track-array\",\"each\",\"incrementClass\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-pagination.hbs"
    }
  });
});