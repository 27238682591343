define("ember-data-storefront/services/storefront", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // do not delete this service! it's being used to communicte cached payloads
  // between the client and the browser
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    fastbootDataRequests: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('fastbootDataRequests', {});
    },
    findAll: function findAll() {
      var _this$get;
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadAll instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-find-all',
        until: '1.0.0'
      }));
      return (_this$get = this.get('store')).loadAll.apply(_this$get, arguments);
    },
    loadAll: function loadAll() {
      var _this$get2;
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadAll instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-load-all',
        until: '1.0.0'
      }));
      return (_this$get2 = this.get('store')).loadAll.apply(_this$get2, arguments);
    },
    findRecord: function findRecord() {
      var _this$get3;
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadRecord instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-find-record',
        until: '1.0.0'
      }));
      return (_this$get3 = this.get('store')).findRecord.apply(_this$get3, arguments);
    },
    loadRecord: function loadRecord() {
      var _this$get4;
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadRecord instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-load-record',
        until: '1.0.0'
      }));
      return (_this$get4 = this.get('store')).findRecord.apply(_this$get4, arguments);
    },
    hasLoadedIncludesForRecord: function hasLoadedIncludesForRecord() {
      var _this$get5;
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.hasLoadedIncludesForRecord instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-has-loaded-includes-for-record',
        until: '1.0.0'
      }));
      return (_this$get5 = this.get('store')).hasLoadedIncludesForRecord.apply(_this$get5, arguments);
    },
    resetCache: function resetCache() {
      var _this$get6;
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.resetCache instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-reset-cache',
        until: '1.0.0'
      }));
      return (_this$get6 = this.get('store')).resetCache.apply(_this$get6, arguments);
    }
  });
});