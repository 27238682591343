define("ember-google-maps/templates/components/g-map/info-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "j/+evekf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"_targetPane\"]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"_targetPane\"]]],null]],[[\"guid\",\"guid\",\"insertBefore\"],[\"%cursor:0%\",\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"      \"],[16,1,[[27,[26,1,\"Expression\"],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"content\",\"publicAPI\",\"if\",\"-clear-element\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/info-window.hbs"
    }
  });
});