define("ember-csz/helpers/csz", ["exports", "csz"], function (_exports, _csz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function csz(params /*, hash*/) {
    return (0, _csz.default)(params);
  });
});