define("ember-metrics/metrics-adapters/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function makeToString(ret) {
    return function () {
      return ret;
    };
  }
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      (true && !(false) && Ember.assert("[ember-metrics] ".concat(this.toString(), " must implement the init hook!")));
    },
    willDestroy: function willDestroy() {
      (true && !(false) && Ember.assert("[ember-metrics] ".concat(this.toString(), " must implement the willDestroy hook!")));
    },
    toString: function toString() {
      var hasToStringExtension = Ember.typeOf(this.toStringExtension) === 'function';
      var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      var ret = "ember-metrics@metrics-adapter:".concat(extension, ":").concat(Ember.guidFor(this));
      this.toString = makeToString(ret);
      return ret;
    },
    metrics: null,
    config: null,
    identify: function identify() {},
    trackEvent: function trackEvent() {},
    trackPage: function trackPage() {},
    alias: function alias() {}
  }).reopenClass({
    supportsFastBoot: false
  });
});