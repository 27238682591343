define("ember-cli-materialize/templates/components/md-modal-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fLGNM49V",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[13,\"id\",[32,[[27,[26,0,\"AppendSingleId\"],[]]]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modalContainerId\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-modal-container.hbs"
    }
  });
});