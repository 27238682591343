define("ember-cli-materialize/templates/components/md-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eZBHJuGz",
    "block": "{\"symbols\":[],\"statements\":[[9,\"a\",true],[13,\"class\",[32,[[31,12,2,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"active\"],null]]],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"active\",\"if\",\"title\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-tab.hbs"
    }
  });
});