define("@kockpit/ember-gantt/components/gantt-line", ["exports", "@kockpit/ember-gantt/utils/date-util", "@kockpit/ember-gantt/templates/components/gantt-line"], function (_exports, _dateUtil, _ganttLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   Use the line component within your gantt chart part. You may use it in inline or block notation.
  
   ### Usage
   Use as a inline or block level component with sub-components title, inlineChilds, childLine, barContent and milestone
   components as children:
   ```handlebars
    {{#gantt-chart dayWidth=10 as |chart|}}
  
      {{! loop over your data to add as many lines as you need }}
      {{#each projects as |p|}}
        {{chart.line dateStart=p.dateStart dateEnd=p.dateEnd color=p.color onDateChange=(action 'myCallback' p)}}
        {{! -> callback receives new dateStart, dateEnd followed by your own arguments}}
      {{/each}}
  
      {{#chart.line dateStart=myStartDate dateEnd=myEndDate color="red" as |line|}}
  
        {{! milestone }}
        {{line.milestone date=myDate title="GoLive"}}
  
        {{! add a child line - this can be done recursively }}
        {{line.childLine dateStart=myStartDate dateEnd=myEndDate color="blue"}}
  
      {{/chart.ine}}
  
    {{/gantt-chart}}
   ```
  
   @class GanttLine
   @namespace Components
   @extends Ember.Component
   @public
   @yield {GanttLineTitle} line.title
   @yield {GanttInlineChilds} line.inlineChilds
   @yield {GanttLine} line.childLine
   @yield {GanttLineBarcontent} line.barContent
   @yield {GanttMilestone} line.milestone
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _ganttLine.default,
    /**
     * link to chart object
     *
     * @property chart
     * @type object
     * @default null
     * @private
     */
    chart: null,
    /**
     * link to parent line object
     *
     * @property parentLine
     * @type object
     * @default null
     * @private
     */
    parentLine: null,
    /**
     * Width of day-cell in px
     *
     * @property dayWidth
     * @type int
     * @default 20
     * @private
     */
    dayWidth: Ember.computed.alias('chart.dayWidth'),
    /**
     * Line-title, shown at the left
     *
     * @property title
     * @argument title
     * @type Date
     * @default null
     * @public
     */
    title: '',
    /**
     * start-date of bar
     *
     * @property dateStart
     * @argument dateStart
     * @type Date
     * @default null
     * @public
     */
    dateStart: null,
    _start: Ember.computed('dateStart', 'chart.viewStartDate', function () {
      var max = Math.max(_dateUtil.default.getNewDate(Ember.get(this, 'dateStart')), Ember.get(this, 'chart.viewStartDate'));
      return _dateUtil.default.getNewDate(max);
    }),
    /**
     * end-date of bar
     *
     * @property dateEnd
     * @argument dateEnd
     * @type Date
     * @default null
     * @public
     */
    dateEnd: null,
    _end: Ember.computed('dateEnd', 'chart.viewEndDate', function () {
      var min = Math.min(_dateUtil.default.getNewDate(Ember.get(this, 'dateEnd')), Ember.get(this, 'chart.viewEndDate'));
      return _dateUtil.default.getNewDate(min);
    }),
    /**
     * bar color
     *
     * @property color
     * @argument color
     * @type string
     * @default null
     * @public
     */
    color: null,
    /**
     * If bar can be manipulated: resize/move.
     * It's possible to make parents non-editable and use max/min date of childs to align parent gantt-bar
     *
     * @property isEditable
     * @argument isEditable
     * @type bool
     * @default false
     * @public
     */
    isEditable: false,
    /**
     * Reference to chart element
     *
     * @property chartElement
     * @type object
     * @default null
     * @private
     */
    chartElement: null,
    /**
     * Reference to bar element
     *
     * @property barElement
     * @type object
     * @default null
     * @private
     */
    barElement: null,
    /**
     * Callback, when start/end date changed due to moving or resizing.
     * The date is thereby updated all the time. This callback is executed once, after resize/move finally ends.
     * The callback function receiveds the following attributes (in this order):
     * - `startDate`    new start date
     * - `endDate`      new end date
     *
     * @event onDateChange
     * @argument onDateChange
     * @type function
     * @default null
     * @public
     */
    onDateChange: null,
    classNames: ['gantt-line-wrap'],
    classNameBindings: ['isResizing', 'isMoving'],
    init: function init() {
      this._super.apply(this, arguments);
      if (!this._handleMoveStart) {
        this._handleMoveStart = Ember.run.bind(this, this.activateMove);
        this._handleResizeLeft = Ember.run.bind(this, this.activateResizeLeft);
        this._handleResizeRight = Ember.run.bind(this, this.activateResizeRight);
        this._handleResizeMove = Ember.run.bind(this, this.resizeBar);
        this._handleFinish = Ember.run.bind(this, this.deactivateAll);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // bar reference
      var bar = this.element.querySelector('.gantt-line-bar');
      Ember.set(this, 'barElement', bar);

      // chart reference
      var chart = Ember.get(this, 'chart').element;
      Ember.set(this, 'chartElement', chart);

      // only if editable
      if (Ember.get(this, 'isEditable')) {
        this.makeEditable();
      }
    },
    willDestroyelement: function willDestroyelement() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'isEditable')) {
        this.removeEditable();
      }
    },
    observeEditable: Ember.observer('isEditable', function () {
      var func = Ember.get(this, 'isEditable') ? this.makeEditable : this.removeEditable;
      Ember.run.next(this, func); // wait for rendering resize-handlers
    }),
    makeEditable: function makeEditable() {
      // register resize and drag handlers
      var bar = Ember.get(this, 'barElement');
      var barResizeL = bar.querySelector('.bar-resize-l');
      var barResizeR = bar.querySelector('.bar-resize-r');

      // resize
      barResizeL.addEventListener('mousedown', this._handleResizeLeft);
      barResizeR.addEventListener('mousedown', this._handleResizeRight);

      // move
      bar.addEventListener('mousedown', this._handleMoveStart);

      // resize/move
      document.addEventListener('mousemove', this._handleResizeMove);
      document.addEventListener('mouseup', this._handleFinish);
    },
    removeEditable: function removeEditable() {
      var bar = Ember.get(this, 'barElement');
      var barResizeL = bar.querySelector('.bar-resize-l');
      var barResizeR = bar.querySelector('.bar-resize-r');

      // unregister resize and drag helpers
      bar.removeEventListener('mousedown', this._handleMoveStart);
      barResizeL.removeEventListener('mousedown', this._handleResizeLeft);
      barResizeR.removeEventListener('mousedown', this._handleResizeRight);
      document.removeEventListener('mousemove', this._handleResizeMove);
      document.removeEventListener('mouseup', this._handleFinish);
    },
    // Bar offset from left (in px)
    // Calculated from date-start and dayWidth (in chart component)
    barOffset: Ember.computed('_start', 'dayWidth', function () {
      return Ember.get(this, 'chart').dateToOffset(Ember.get(this, '_start'));
    }),
    // width of bar on months
    barWidth: Ember.computed('_start', '_end', 'dayWidth', function () {
      return Ember.get(this, 'chart').dateToOffset(Ember.get(this, '_end'), Ember.get(this, '_start'), true);
    }),
    // styling for left/width
    barStyle: Ember.computed('barOffset', 'barWidth', function () {
      var style = "left:".concat(Ember.get(this, 'barOffset'), "px;width:").concat(Ember.get(this, 'barWidth'), "px;");
      if (Ember.get(this, 'color')) {
        style += "background-color:".concat(Ember.get(this, 'color'));
      }
      return Ember.String.htmlSafe(style);
    }),
    // TODO: title -> ?
    barTitle: Ember.computed('dateStart', 'dateEnd', function () {
      var start = Ember.get(this, 'dateStart').toLocaleDateString() || String(Ember.get(this, 'dateStart')),
        end = Ember.get(this, 'dateEnd').toLocaleDateString() || String(Ember.get(this, 'dateEnd')),
        days = _dateUtil.default.diffDays(Ember.get(this, 'dateStart'), Ember.get(this, 'dateEnd'), true);
      if (start && end) {
        return Ember.String.htmlSafe("".concat(start, " - ").concat(end, " (").concat(days, ")"));
      }
      return '';
    }),
    // get element offset to parent (including scroll)
    // TODO: use from util package or ember?
    offsetLeft: function offsetLeft(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      return rect.left + scrollLeft;
    },
    // RESIZING FUNCTIONS
    isEditing: Ember.computed.or('isResizingLeft', 'isResizingRight', 'isMoving'),
    isResizing: Ember.computed.or('isResizingLeft', 'isResizingRight'),
    isResizingLeft: false,
    isResizingRight: false,
    timelineOffset: 0,
    activateResizeLeft: function activateResizeLeft() {
      this.initTimlineOffset();
      Ember.set(this, 'isResizingLeft', true);
    },
    activateResizeRight: function activateResizeRight() {
      this.initTimlineOffset();
      Ember.set(this, 'isResizingRight', true);
    },
    initTimlineOffset: function initTimlineOffset() {
      var timelineElement = Ember.get(this, 'chartElement').querySelector('.gantt-line-timeline');
      Ember.set(this, 'timelineOffset', this.offsetLeft(timelineElement));
      Ember.set(this, 'movingMouseOffset', 0);
    },
    // MOVE FUNCTION
    isMoving: false,
    movingDays: 0,
    movingMouseOffset: 0,
    activateMove: function activateMove(e) {
      e.preventDefault();
      this.initTimlineOffset();

      // remember days-duration of line
      var moveDays = Math.floor(Math.abs(Ember.get(this, 'dateStart').getTime() - Ember.get(this, 'dateEnd').getTime()) / 86400000);

      // remember click-offset for adjusting mouse-to-bar
      var mouseOffset = e.clientX - this.offsetLeft(e.target);
      Ember.set(this, 'movingDays', moveDays);
      Ember.set(this, 'movingMouseOffset', mouseOffset);
      Ember.set(this, 'isMoving', true);
    },
    resizeBar: function resizeBar(e) {
      if (this.isDestroyed) return;
      if (!Ember.get(this, 'isEditing')) return;
      e.preventDefault();

      // offset -> start/end-date
      var offsetLeft = e.clientX - Ember.get(this, 'timelineOffset') - Ember.get(this, 'movingMouseOffset');
      var dateOffset = Ember.get(this, 'chart').offsetToDate(offsetLeft);

      // resize left
      if (Ember.get(this, 'isResizingLeft')) {
        dateOffset = dateOffset > Ember.get(this, 'dateEnd') ? Ember.get(this, 'dateEnd') : dateOffset; // dont allow lower than start
        Ember.set(this, 'dateStart', dateOffset);

        // resize right
      } else if (Ember.get(this, 'isResizingRight')) {
        dateOffset = dateOffset < Ember.get(this, 'dateStart') ? Ember.get(this, 'dateStart') : dateOffset; // dont allow lower than start
        Ember.set(this, 'dateEnd', dateOffset);

        // move
      } else if (Ember.get(this, 'isMoving')) {
        var dateOffsetEnd = new Date(dateOffset.getTime() + Ember.get(this, 'movingDays') * 86400000);
        Ember.set(this, 'dateStart', dateOffset);
        Ember.set(this, 'dateEnd', dateOffsetEnd);
      }
    },
    deactivateAll: function deactivateAll() {
      if (this.isDestroyed) return;

      // check if something happened on this line
      var action = '';
      if (Ember.get(this, 'isResizing')) {
        action = 'resize';
      } else if (Ember.get(this, 'isMoving')) {
        action = 'move';
      }
      Ember.set(this, 'isResizingLeft', false);
      Ember.set(this, 'isResizingRight', false);
      Ember.set(this, 'isMoving', false);
      if (!Ember.isEmpty(action)) {
        var callback = Ember.get(this, 'onDateChange');
        if (typeof callback === 'function') {
          callback(Ember.get(this, 'dateStart'), Ember.get(this, 'dateEnd'), action);
        }
      }
    }
  });
});