define("ember-metrics/services/metrics", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = Object.keys;
  var _default = _exports.default = Ember.Service.extend({
    /**
     * Cached adapters to reduce multiple expensive lookups.
     *
     * @property _adapters
     * @private
     * @type Object
     * @default null
     */
    _adapters: null,
    /**
     * Contextual information attached to each call to an adapter. Often you'll
     * want to include things like `currentUser.name` with every event or page
     * view  that's tracked. Any properties that you bind to `metrics.context`
     * will be merged into the options for every service call.
     *
     * @property context
     * @type Object
     * @default null
     */
    context: null,
    /**
     * Indicates whether calls to the service will be forwarded to the adapters
     *
     * @property enabled
     * @type Boolean
     * @default true
     */
    enabled: true,
    /**
     * When the Service is created, activate adapters that were specified in the
     * configuration. This config is injected into the Service as
     * `options`.
     *
     * @method init
     * @param {Void}
     * @return {Void}
     */
    init: function init() {
      var adapters = this.options.metricsAdapters || Ember.A();
      var owner = Ember.getOwner(this);
      owner.registerOptionsForType('ember-metrics@metrics-adapter', {
        instantiate: false
      });
      owner.registerOptionsForType('metrics-adapter', {
        instantiate: false
      });
      Ember.set(this, 'appEnvironment', this.options.environment || 'development');
      Ember.set(this, '_adapters', {});
      Ember.set(this, 'context', {});
      this.activateAdapters(adapters);
      this._super.apply(this, arguments);
    },
    identify: function identify() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this.invoke.apply(this, ['identify'].concat(args));
    },
    alias: function alias() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      this.invoke.apply(this, ['alias'].concat(args));
    },
    trackEvent: function trackEvent() {
      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }
      this.invoke.apply(this, ['trackEvent'].concat(args));
    },
    trackPage: function trackPage() {
      for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }
      this.invoke.apply(this, ['trackPage'].concat(args));
    },
    /**
     * Instantiates the adapters specified in the configuration and caches them
     * for future retrieval.
     *
     * @method activateAdapters
     * @param {Array} adapterOptions
     * @return {Object} instantiated adapters
     */
    activateAdapters: function activateAdapters() {
      var _this = this;
      var adapterOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var appEnvironment = this.appEnvironment;
      var cachedAdapters = this._adapters;
      var activatedAdapters = {};
      adapterOptions.filter(function (adapterOption) {
        return _this._filterEnvironments(adapterOption, appEnvironment);
      }).forEach(function (adapterOption) {
        var name = adapterOption.name,
          config = adapterOption.config;
        var adapterClass = _this._lookupAdapter(name);
        if (typeof FastBoot === 'undefined' || adapterClass.supportsFastBoot) {
          var adapter = cachedAdapters[name] || _this._activateAdapter({
            adapterClass: adapterClass,
            config: config
          });
          Ember.set(activatedAdapters, name, adapter);
        }
      });
      return Ember.set(this, '_adapters', activatedAdapters);
    },
    /**
     * Invokes a method on the passed adapter, or across all activated adapters if not passed.
     *
     * @method invoke
     * @param {String} methodName
     * @param {Rest} args
     * @return {Void}
     */
    invoke: function invoke(methodName) {
      if (!this.enabled) {
        return;
      }
      var cachedAdapters = this._adapters;
      var allAdapterNames = keys(cachedAdapters);
      var _ref = (arguments.length <= 1 ? 0 : arguments.length - 1) > 1 ? [Ember.makeArray(arguments.length <= 1 ? undefined : arguments[1]), arguments.length <= 2 ? undefined : arguments[2]] : [allAdapterNames, arguments.length <= 1 ? undefined : arguments[1]],
        _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        selectedAdapterNames = _ref2[0],
        options = _ref2[1];
      var context = Ember.assign({}, this.context);
      var mergedOptions = Ember.assign(context, options);
      selectedAdapterNames.map(function (adapterName) {
        return cachedAdapters[adapterName];
      }).forEach(function (adapter) {
        return adapter && adapter[methodName](mergedOptions);
      });
    },
    /**
     * On teardown, destroy cached adapters together with the Service.
     *
     * @method willDestroy
     * @param {Void}
     * @return {Void}
     */
    willDestroy: function willDestroy() {
      var cachedAdapters = this._adapters;
      for (var adapterName in cachedAdapters) {
        cachedAdapters[adapterName].destroy();
      }
    },
    /**
     * Instantiates an adapter.
     *
     * @method _activateAdapter
     * @param {Object}
     * @private
     * @return {Adapter}
     */
    _activateAdapter: function _activateAdapter(_ref3) {
      var adapterClass = _ref3.adapterClass,
        config = _ref3.config;
      return adapterClass.create(Ember.getOwner(this).ownerInjection(), {
        this: this,
        config: config
      });
    },
    /**
     * Looks up the adapter from the container. Prioritizes the consuming app's
     * adapters over the addon's adapters.
     *
     * @method _lookupAdapter
     * @param {String} adapterName
     * @private
     * @return {Adapter} a local adapter or an adapter from the addon
     */
    _lookupAdapter: function _lookupAdapter(adapterName) {
      (true && !(adapterName) && Ember.assert('[ember-metrics] Could not find metrics adapter without a name.', adapterName));
      var dasherizedAdapterName = Ember.String.dasherize(adapterName);
      var availableAdapter = Ember.getOwner(this).lookup("ember-metrics@metrics-adapter:".concat(dasherizedAdapterName));
      var localAdapter = Ember.getOwner(this).lookup("metrics-adapter:".concat(dasherizedAdapterName));
      var adapter = localAdapter || availableAdapter;
      (true && !(adapter) && Ember.assert("[ember-metrics] Could not find metrics adapter ".concat(adapterName, "."), adapter));
      return adapter;
    },
    /**
     * Predicate that Filters out adapters that should not be activated in the
     * current application environment. Defaults to all environments if the option
     * is `all` or undefined.
     *
     * @method _filterEnvironments
     * @param {Object} adapterOption
     * @param {String} appEnvironment
     * @private
     * @return {Boolean} should an adapter be activated
     */
    _filterEnvironments: function _filterEnvironments(adapterOption, appEnvironment) {
      var environments = adapterOption.environments;
      environments = environments || ['all'];
      var wrappedEnvironments = Ember.A(environments);
      return wrappedEnvironments.indexOf('all') > -1 || wrappedEnvironments.indexOf(appEnvironment) > -1;
    }
  });
});