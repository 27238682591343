define("ember-cli-materialize/mixins/uses-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    _mdSettings: Ember.computed(function () {
      // jscs:disable disallowDirectPropertyAccess
      var owner = Ember.getOwner ? Ember.getOwner(this) : this.get('container');
      // jscs:enable disallowDirectPropertyAccess
      return owner.lookup('service:materialize-settings');
    })
  });
});