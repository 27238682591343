define("ember-cli-materialize/templates/components/md-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CskogpSV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,17,14,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],[[\"extraClasses\"],[[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,0,0,0,[27,[26,4,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"iconPosition\",\"icon\",\"bw-compat-icon\",\"if\",\"text\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-btn.hbs"
    }
  });
});