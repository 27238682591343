define("ember-math-helpers/helpers/random", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/typeof"], function (_exports, _slicedToArray2, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.random = random;
  var min = Math.min,
    max = Math.max;

  // @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#max(0, min(MAX_DECIMALS, decimals))));
  var MAX_DECIMALS = 20;

  // 💡 Using Number.toFixed, we'll get rounding for free alongside
  // decimal precision. We'll default to whole-number rounding simply
  // by defaulting `decimals` to 0
  var DEFAULT_OPTS = {
    decimals: 0
  };

  /**
   * Executes `Math.random` with the provided upper-bounded randoms, lower- and upper-bounded randoms,
   * and the option to configure decimal precision.
   *
   * ```hbs
   * {{random}} or {{random decimals=4}}
   * ```
   *
   * ```hbs
   * {{random 42}} or {{random 42 decimals=4}}
   * ```
   *
   * ```hbs
   * {{random 21 1797}} or {{random 21 1797 decimals=4}}
   * ```
   *
   * @param {number} upperBound
   * @param {number} lowerBound
   * @param {object} { decimals } decimal precision value
   * @return {number} The random generated number
   */
  function random(params) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_OPTS,
      decimals = _ref.decimals;
    // no positional args, but only an options hash from named args
    if ((0, _typeof2.default)(params) === 'object' && !Ember.isArray(params)) {
      decimals = typeof params.decimals !== 'undefined' ? params.decimals : DEFAULT_OPTS.decimals;
      return +Math.random().toFixed(max(0, min(MAX_DECIMALS, decimals)));
    }

    // one positional arg: treat it as an upper bound
    if (params && params.length === 1) {
      var _params = (0, _slicedToArray2.default)(params, 1),
        upperBound = _params[0];
      return +(Math.random() * upperBound).toFixed(max(0, min(MAX_DECIMALS, decimals)));
    }

    // two positional args: use them to derive upper and lower bounds
    if (params && params.length === 2) {
      var _params2 = (0, _slicedToArray2.default)(params, 2),
        lowerBound = _params2[0],
        _upperBound = _params2[1];

      // for convenience, swap if a higher number is accidentally passed first
      if (_upperBound < lowerBound) {
        var _ref2 = [_upperBound, lowerBound];
        lowerBound = _ref2[0];
        _upperBound = _ref2[1];
      }
      return +(lowerBound + Math.random() * (_upperBound - lowerBound)).toFixed(max(0, min(MAX_DECIMALS, decimals)));
    }

    // no positional or named args: just return Math.random() w/ default decimal precision
    return +Math.random().toFixed(max(0, min(MAX_DECIMALS, decimals)));
  }
  var _default = _exports.default = Ember.Helper.helper(random);
});