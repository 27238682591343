define("ember-cli-materialize/templates/components/md-fixed-btns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cPMXHl2P",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,0,0,0,[31,2,6,[27,[26,2,\"CallHead\"],[]],null,[[\"icon\",\"class\",\"action\"],[[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]],\"fireButtonAction\"]]]],[1,1,0,0,\"\\n\\n\"],[9,\"ul\",true],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"_btnClassString\",\"btnIcon\",\"md-btn\"]}",
    "meta": {
      "moduleName": "ember-cli-materialize/templates/components/md-fixed-btns.hbs"
    }
  });
});